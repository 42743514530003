// SimpleNotifyChain.tsx
import React from 'react';
import styles from './SimpleNotifyChain.module.scss';
import { NotifyChainDetails } from '@perentie/common';

export interface ReadOnlyNotifyChainProps {
  chain?: NotifyChainDetails | null;
}

const SimpleNotifyChain: React.FC<ReadOnlyNotifyChainProps> = ({ chain }) => {
  const renderChainLinks = () => {
    if (!chain?.links?.length) {
      return (
        <div className={styles.emptyState}>
          No notify chain assigned
        </div>
      );
    }

    return chain.links.map(link => (
      <div
        key={link.level}
        className={styles.chainLink}
      >
        <div className={styles.levelHeader}>
          <span className={styles.levelText}>
            Level {link.level}
          </span>
        </div>
        <div className={styles.userFlex}>
          {Object.entries(link.userMap || {}).map(([userId, user]) => (
            <React.Fragment key={userId}>
              <div className={styles.username}>
                {user.firstName} {user.lastName}
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    ));
  };

  return (
    <div className={styles.card}>
      <div className={styles.content}>
        {renderChainLinks()}
      </div>
    </div>
  );
};

export default SimpleNotifyChain;