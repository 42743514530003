/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserBasicDetails } from './UserBasicDetails';
import {
    UserBasicDetailsFromJSON,
    UserBasicDetailsFromJSONTyped,
    UserBasicDetailsToJSON,
} from './UserBasicDetails';

/**
 * 
 * @export
 * @interface AlertBasicDetails
 */
export interface AlertBasicDetails {
    /**
     * 
     * @type {string}
     * @memberof AlertBasicDetails
     */
    objectId?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertBasicDetails
     */
    componentId?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertBasicDetails
     */
    componentName?: string;
    /**
     * 
     * @type {Date}
     * @memberof AlertBasicDetails
     */
    created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AlertBasicDetails
     */
    ended?: Date;
    /**
     * 
     * @type {string}
     * @memberof AlertBasicDetails
     */
    status?: AlertBasicDetailsStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AlertBasicDetails
     */
    statusDisplay?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertBasicDetails
     */
    componentState?: AlertBasicDetailsComponentStateEnum;
    /**
     * 
     * @type {UserBasicDetails}
     * @memberof AlertBasicDetails
     */
    handlingUser?: UserBasicDetails;
    /**
     * 
     * @type {number}
     * @memberof AlertBasicDetails
     */
    numComments?: number;
    /**
     * 
     * @type {number}
     * @memberof AlertBasicDetails
     */
    supportMinutes?: number;
    /**
     * 
     * @type {string}
     * @memberof AlertBasicDetails
     */
    reference?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertBasicDetails
     */
    clientReference?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertBasicDetails
     */
    closeSummary?: string;
}


/**
 * @export
 */
export const AlertBasicDetailsStatusEnum = {
    ActiveOpen: 'ACTIVE_OPEN',
    NotActiveOpen: 'NOT_ACTIVE_OPEN',
    Closed: 'CLOSED'
} as const;
export type AlertBasicDetailsStatusEnum = typeof AlertBasicDetailsStatusEnum[keyof typeof AlertBasicDetailsStatusEnum];

/**
 * @export
 */
export const AlertBasicDetailsComponentStateEnum = {
    Unknown: 'UNKNOWN',
    Ok: 'OK',
    Info: 'INFO',
    Warning: 'WARNING',
    Severe: 'SEVERE',
    Critical: 'CRITICAL'
} as const;
export type AlertBasicDetailsComponentStateEnum = typeof AlertBasicDetailsComponentStateEnum[keyof typeof AlertBasicDetailsComponentStateEnum];


/**
 * Check if a given object implements the AlertBasicDetails interface.
 */
export function instanceOfAlertBasicDetails(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AlertBasicDetailsFromJSON(json: any): AlertBasicDetails {
    return AlertBasicDetailsFromJSONTyped(json, false);
}

export function AlertBasicDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertBasicDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'objectId': !exists(json, 'objectId') ? undefined : json['objectId'],
        'componentId': !exists(json, 'component_id') ? undefined : json['component_id'],
        'componentName': !exists(json, 'componentName') ? undefined : json['componentName'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'ended': !exists(json, 'ended') ? undefined : (new Date(json['ended'])),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'statusDisplay': !exists(json, 'statusDisplay') ? undefined : json['statusDisplay'],
        'componentState': !exists(json, 'componentState') ? undefined : json['componentState'],
        'handlingUser': !exists(json, 'handlingUser') ? undefined : UserBasicDetailsFromJSON(json['handlingUser']),
        'numComments': !exists(json, 'numComments') ? undefined : json['numComments'],
        'supportMinutes': !exists(json, 'supportMinutes') ? undefined : json['supportMinutes'],
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
        'clientReference': !exists(json, 'clientReference') ? undefined : json['clientReference'],
        'closeSummary': !exists(json, 'closeSummary') ? undefined : json['closeSummary'],
    };
}

export function AlertBasicDetailsToJSON(value?: AlertBasicDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'objectId': value.objectId,
        'component_id': value.componentId,
        'componentName': value.componentName,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'ended': value.ended === undefined ? undefined : (value.ended.toISOString()),
        'status': value.status,
        'statusDisplay': value.statusDisplay,
        'componentState': value.componentState,
        'handlingUser': UserBasicDetailsToJSON(value.handlingUser),
        'numComments': value.numComments,
        'supportMinutes': value.supportMinutes,
        'reference': value.reference,
        'clientReference': value.clientReference,
        'closeSummary': value.closeSummary,
    };
}

