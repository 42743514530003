/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SupportReportResult } from './SupportReportResult';
import {
    SupportReportResultFromJSON,
    SupportReportResultFromJSONTyped,
    SupportReportResultToJSON,
} from './SupportReportResult';

/**
 * 
 * @export
 * @interface RESTResultSupportReportResult
 */
export interface RESTResultSupportReportResult {
    /**
     * 
     * @type {string}
     * @memberof RESTResultSupportReportResult
     */
    error?: string;
    /**
     * 
     * @type {SupportReportResult}
     * @memberof RESTResultSupportReportResult
     */
    data?: SupportReportResult;
}

/**
 * Check if a given object implements the RESTResultSupportReportResult interface.
 */
export function instanceOfRESTResultSupportReportResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RESTResultSupportReportResultFromJSON(json: any): RESTResultSupportReportResult {
    return RESTResultSupportReportResultFromJSONTyped(json, false);
}

export function RESTResultSupportReportResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): RESTResultSupportReportResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'error': !exists(json, 'error') ? undefined : json['error'],
        'data': !exists(json, 'data') ? undefined : SupportReportResultFromJSON(json['data']),
    };
}

export function RESTResultSupportReportResultToJSON(value?: RESTResultSupportReportResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'error': value.error,
        'data': SupportReportResultToJSON(value.data),
    };
}

