export enum TimeFormat
{
    MINUTES = "MINUTES",
    HOURS_AND_MINUTES = "HOURS_AND_MINUTES",
    HH_MM = "HH_MM",
    VERBOSE = "VERBOSE",
    COMPACT = "COMPACT",
    DECIMAL = "DECIMAL",
    SECONDS = "SECONDS",
}

class TimeUtil {

    public formatMinutes(minutes: number, format: string): string {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;

        // Validate and cast the format
        if (!Object.values(TimeFormat).includes(format as TimeFormat)) {
            throw new Error(`Invalid time format: ${format}`);
        }

        switch (format as TimeFormat) {
            case TimeFormat.MINUTES:
                return `${minutes}min`;
            case TimeFormat.HOURS_AND_MINUTES:
                if (minutes == 0) {
                    return '0min';
                }
                return `${hours == 0 ? '' : hours}${hours == 1 ? 'hr' : hours == 0 ? '' : 'hrs'} ${remainingMinutes == 0 ? '' : remainingMinutes}${remainingMinutes == 0 ? '' : 'min'}`;
            case TimeFormat.HH_MM:
                return `${String(hours).padStart(2, '0')}:${String(remainingMinutes).padStart(2, '0')}`;
            case TimeFormat.VERBOSE:
                return `${hours} hour${hours !== 1 ? 's' : ''} and ${remainingMinutes} minute${remainingMinutes !== 1 ? 's' : ''}`;
            case TimeFormat.COMPACT:
                return `${hours}h${remainingMinutes}m`;
            case TimeFormat.DECIMAL:
                return `${(minutes / 60).toFixed(2)} hours`;
            case TimeFormat.SECONDS:
                return `${minutes * 60}s`;
            default:
                throw new Error("Invalid time format");
        }
    }

    public nearestQuarterHour(date?: Date): Date {
        if (!date) {
            date = new Date();
        }
        date.setMilliseconds(0);
        date.setSeconds(0);
        date.setMinutes(Math.round(date.getMinutes() / 15) * 15);
        return date;
    }

}

export default TimeUtil;


