/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ComponentReportSummary } from './ComponentReportSummary';
import {
    ComponentReportSummaryFromJSON,
    ComponentReportSummaryFromJSONTyped,
    ComponentReportSummaryToJSON,
} from './ComponentReportSummary';
import type { ReportRequest } from './ReportRequest';
import {
    ReportRequestFromJSON,
    ReportRequestFromJSONTyped,
    ReportRequestToJSON,
} from './ReportRequest';

/**
 * 
 * @export
 * @interface SupportReportResult
 */
export interface SupportReportResult {
    /**
     * 
     * @type {ReportRequest}
     * @memberof SupportReportResult
     */
    criteria?: ReportRequest;
    /**
     * 
     * @type {Array<ComponentReportSummary>}
     * @memberof SupportReportResult
     */
    summaries?: Array<ComponentReportSummary>;
}

/**
 * Check if a given object implements the SupportReportResult interface.
 */
export function instanceOfSupportReportResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SupportReportResultFromJSON(json: any): SupportReportResult {
    return SupportReportResultFromJSONTyped(json, false);
}

export function SupportReportResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupportReportResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'criteria': !exists(json, 'criteria') ? undefined : ReportRequestFromJSON(json['criteria']),
        'summaries': !exists(json, 'summaries') ? undefined : ((json['summaries'] as Array<any>).map(ComponentReportSummaryFromJSON)),
    };
}

export function SupportReportResultToJSON(value?: SupportReportResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'criteria': ReportRequestToJSON(value.criteria),
        'summaries': value.summaries === undefined ? undefined : ((value.summaries as Array<any>).map(ComponentReportSummaryToJSON)),
    };
}

