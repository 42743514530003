/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AlertBasicDetails } from './AlertBasicDetails';
import {
    AlertBasicDetailsFromJSON,
    AlertBasicDetailsFromJSONTyped,
    AlertBasicDetailsToJSON,
} from './AlertBasicDetails';
import type { ComponentBasicDetails } from './ComponentBasicDetails';
import {
    ComponentBasicDetailsFromJSON,
    ComponentBasicDetailsFromJSONTyped,
    ComponentBasicDetailsToJSON,
} from './ComponentBasicDetails';
import type { UserBasicDetails } from './UserBasicDetails';
import {
    UserBasicDetailsFromJSON,
    UserBasicDetailsFromJSONTyped,
    UserBasicDetailsToJSON,
} from './UserBasicDetails';

/**
 * 
 * @export
 * @interface ComponentReportSummary
 */
export interface ComponentReportSummary {
    /**
     * 
     * @type {ComponentBasicDetails}
     * @memberof ComponentReportSummary
     */
    componentDetails?: ComponentBasicDetails;
    /**
     * 
     * @type {number}
     * @memberof ComponentReportSummary
     */
    totalAlerts?: number;
    /**
     * 
     * @type {number}
     * @memberof ComponentReportSummary
     */
    totalSupportMinutes?: number;
    /**
     * 
     * @type {Array<AlertBasicDetails>}
     * @memberof ComponentReportSummary
     */
    alertList?: Array<AlertBasicDetails>;
    /**
     * 
     * @type {Array<UserBasicDetails>}
     * @memberof ComponentReportSummary
     */
    handlers?: Array<UserBasicDetails>;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof ComponentReportSummary
     */
    handlerMinutes?: { [key: string]: number; };
}

/**
 * Check if a given object implements the ComponentReportSummary interface.
 */
export function instanceOfComponentReportSummary(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ComponentReportSummaryFromJSON(json: any): ComponentReportSummary {
    return ComponentReportSummaryFromJSONTyped(json, false);
}

export function ComponentReportSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComponentReportSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'componentDetails': !exists(json, 'componentDetails') ? undefined : ComponentBasicDetailsFromJSON(json['componentDetails']),
        'totalAlerts': !exists(json, 'totalAlerts') ? undefined : json['totalAlerts'],
        'totalSupportMinutes': !exists(json, 'totalSupportMinutes') ? undefined : json['totalSupportMinutes'],
        'alertList': !exists(json, 'alertList') ? undefined : ((json['alertList'] as Array<any>).map(AlertBasicDetailsFromJSON)),
        'handlers': !exists(json, 'handlers') ? undefined : ((json['handlers'] as Array<any>).map(UserBasicDetailsFromJSON)),
        'handlerMinutes': !exists(json, 'handlerMinutes') ? undefined : json['handlerMinutes'],
    };
}

export function ComponentReportSummaryToJSON(value?: ComponentReportSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'componentDetails': ComponentBasicDetailsToJSON(value.componentDetails),
        'totalAlerts': value.totalAlerts,
        'totalSupportMinutes': value.totalSupportMinutes,
        'alertList': value.alertList === undefined ? undefined : ((value.alertList as Array<any>).map(AlertBasicDetailsToJSON)),
        'handlers': value.handlers === undefined ? undefined : ((value.handlers as Array<any>).map(UserBasicDetailsToJSON)),
        'handlerMinutes': value.handlerMinutes,
    };
}

