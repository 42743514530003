/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LocalTime } from './LocalTime';
import {
    LocalTimeFromJSON,
    LocalTimeFromJSONTyped,
    LocalTimeToJSON,
} from './LocalTime';

/**
 * 
 * @export
 * @interface TimeSpan
 */
export interface TimeSpan {
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    dayMask?: number;
    /**
     * 
     * @type {LocalTime}
     * @memberof TimeSpan
     */
    start?: LocalTime;
    /**
     * 
     * @type {LocalTime}
     * @memberof TimeSpan
     */
    end?: LocalTime;
}

/**
 * Check if a given object implements the TimeSpan interface.
 */
export function instanceOfTimeSpan(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TimeSpanFromJSON(json: any): TimeSpan {
    return TimeSpanFromJSONTyped(json, false);
}

export function TimeSpanFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeSpan {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dayMask': !exists(json, 'dayMask') ? undefined : json['dayMask'],
        'start': !exists(json, 'start') ? undefined : LocalTimeFromJSON(json['start']),
        'end': !exists(json, 'end') ? undefined : LocalTimeFromJSON(json['end']),
    };
}

export function TimeSpanToJSON(value?: TimeSpan | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dayMask': value.dayMask,
        'start': LocalTimeToJSON(value.start),
        'end': LocalTimeToJSON(value.end),
    };
}

