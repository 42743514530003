export function getUTCDateStringYYYYMMDD(date: Date): string {
    const utcDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000).toISOString();
    return new Date(utcDate).toISOString().split('T')[0];
}

export function roundTimeToLastQuarterHour(date: Date | null): Date {
    if (date === null) {
        const date = new Date();
        return roundTimeToLastQuarterHour(date);
    }
    const coeff = 1000 * 60 * 15;
    return new Date(Math.round(date.getTime() / coeff) * coeff);
}