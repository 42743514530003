import {useEffect, useState} from "react";
import {AlertBasicDetails, AlertDetails} from "@perentie/common";
import {perDS} from "../../datasource/WebDS";
import styles from "./ActiveAlertsPage.module.scss"
import BasicAlertCard from "../../components/BasicAlertCard/BasicAlertCard";
import {useNavigate} from "react-router-dom";
import vibe from '../../assets/noalertsvibe.webp';
import PerentieModal from "../../components/Modal/Modal";


function ActiveAlertsPage(){

  const [alerts, setAlerts] = useState<AlertBasicDetails[]>([]);
  const [loading, setLoading] = useState(true);
  const [showCLoseEventDialog, setShowCloseEventDialog] = useState(false);
  const [closeSummary, setCloseSummary] = useState('');

  const [details, setDetails] = useState<AlertBasicDetails|null>(null);

  const navigate = useNavigate();



  const loadAlerts = () => {
    perDS.getOpenAlerts().then(res => {
      setAlerts(res);
      setTimeout(() => {
        setLoading(false);
      },100);
    }).catch( reason => {
      console.log(' problem', reason);
      setLoading(false);
    });
  }

  useEffect(() => {
    loadAlerts();
  }, []);

  const alertClick = (alert: AlertDetails) => {
    navigate('/alert/'+alert.objectId);
  }

  const closeClick = (item: AlertBasicDetails) => {
    setDetails(item);
    setShowCloseEventDialog(true);
  }

  const closeAlert = (alertId: string, summary: string | null) => {

    perDS.closeAlert(alertId, summary ?? '').then(res => {
      loadAlerts();
      setShowCloseEventDialog(false);
    }).catch( reason => {
      console.log(' closeAlert catch', reason);
      setShowCloseEventDialog(false);
      window.alert('Error closing alert ' + reason);
    });

  }

  const showCLoseEventDialogClose = () => {
    setShowCloseEventDialog(false);
    setCloseSummary('');
  }

  const cards = alerts.map( deets => {
    return <BasicAlertCard details={deets} onClick={alertClick} actionClick={(item, action) => closeClick(item)}/>
  });

  return (
    <div className={styles['content']}>
      <div style={{display: loading ? '': 'none'}}>Loading...</div>
      {cards.length == 0 && !loading ?
      <div style={{display:'flex', flex:1,justifyContent:'center', flexDirection:'column', alignItems:'center'}}>
        <div style={{fontFamily:'Inter', fontWeight:'bolder', fontSize:'48px', color:'rgba(0,0,0,0.5)'}}>
          No Open Alerts
        </div>
        <div style={{backgroundImage: `url(${vibe})`, width:400, height:400, backgroundSize:'contain', backgroundRepeat:'no-repeat'}}>
          <div style={{width:'100%', height:'100%', boxShadow: '0 0 50px 50px white inset'}}>
            <div style={{width:'100%', height:'100%', boxShadow: '0 0 20px 20px white inset'}}></div>
          </div>
        </div>
      </div>:
        !loading && <div className={styles['cardGrid']}>{cards}</div>}

      <PerentieModal height={420} title='Close Alert' visible={showCLoseEventDialog} onClose={showCLoseEventDialogClose}
                     footer={
                       <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 12}}>
                         <button className={styles["perentie-button-ghost"]} style={{height: 30, border: 'none', cursor: 'pointer', backgroundColor: 'white'}}
                                 onClick={showCLoseEventDialogClose}>
                           <span>Cancel</span>
                         </button>
                         <button className={styles["perentie-button"]} style={{height: 30, border: 'none', padding: 16}}
                                 onClick={() => {closeAlert(details!.objectId!, closeSummary)}}>
                           <span>Close</span>
                         </button>
                       </div>
                     }
      >
        <p>Are you sure you want to close this alert? This cannot be undone.</p>
        <br/>
        <label>Close Summary</label>
        <textarea rows={8} className={`${styles['textarea']} ${styles['sm']}`} id={'closeSummary'} defaultValue={closeSummary} onChange={(e) => setCloseSummary(e.target.value)}></textarea>
      </PerentieModal>
    </div>
  )
}

export default ActiveAlertsPage;
