import { NotifyChainDetails, NotifyChainLinkDetails, UserDetails } from "@perentie/common";
import styles from './ManageComponentsPage.module.scss';
import { Button, Switch } from "@systemic-design-framework/react";
import { useEffect, useState } from "react";

export interface NotifyChainComponentProps {
  chain?: NotifyChainDetails | null,
  changeNotifyChain: (newChain: NotifyChainDetails) => void,
  editing: boolean,
  users: UserDetails[],
  hideOnCall?: boolean
}

function NotifyChainComponent(props: NotifyChainComponentProps) {
  const [availableUserOptions, setAvailableUserOptions] = useState<string[]>([]);
  const [chain, setChain] = useState<NotifyChainDetails | null | undefined>(props.chain);

  useEffect(() => {
    setChain(props.chain);
  }, [props.chain]);

  useEffect(() => {
    setAvailableUserOptions(getAvailableUserOptions());
  }, [props.users, chain]);

  const getAvailableUserOptions = () => {
    if (!props.users || !chain) {
      return [];
    }
    return props.users
      .filter(user => !chain?.links?.some(link => link.userMap?.hasOwnProperty(user.objectId!)))
      .map(u => u.username + ' (' + u.firstName + ' ' + u.lastName + ')');
  }

  const handleAddUserToChain = (username: string, link: NotifyChainLinkDetails) => {
    if (link.level != undefined) {
      const user = props.users.find(u => 
        (u.username + ' (' + u.firstName + ' ' + u.lastName + ')') === username
      );
      
      if (user) {
        const newChain = { ...chain! };
        const newLink = newChain.links?.find(l => l.level === link.level);
        if (newLink) {
          newLink.userMap![user.objectId!] = user;
          newLink.onCallMap![user.objectId!] = true;
          setChain({ ...newChain });
          props.changeNotifyChain(newChain);
        }
      }
    }
  }

  const handleRemoveUserFromChain = (level: number, userId: string) => {
    const newChain = { ...chain! };
    const link = newChain.links?.find(l => l.level == level);
    if (link) {
      delete link.userMap![userId];
      delete link.onCallMap![userId];
      setChain({ ...newChain });
      props.changeNotifyChain(newChain);
    }
  }

  const AddChainLink = () => {
    const newLevel = chain?.links?.length ? chain.links[chain.links.length - 1].level! + 1 : 1;
    const newLink: NotifyChainLinkDetails = {
      level: newLevel,
      userMap: {},
      onCallMap: {}
    }
    const newChain = { ...chain, links: [...chain?.links ?? [], newLink] };
    setChain({ ...newChain });
    props.changeNotifyChain(newChain);
  }

  const removeChainLink = (level: number) => {
    const newChain = { ...chain! };
    const linkIndex = newChain.links?.findIndex(l => l.level == level) ?? -1;
    if (linkIndex != -1) {
      newChain.links?.splice(linkIndex, 1);
      setChain({ ...newChain });
      props.changeNotifyChain(newChain);
    }
  }

  const onCallChange = (level: number, userId: string, isOnCall: boolean) => {
    const newChain = { ...chain! };
    const link = newChain.links?.find(l => l.level == level);
    if (link) {
      link.onCallMap![userId] = isOnCall;
      setChain({ ...newChain });
      props.changeNotifyChain(newChain);
    }
  }

  const ChainLink = (props: { link: NotifyChainLinkDetails, editing: boolean, users: UserDetails[] , isLastLevel: boolean, hideOnCall?: boolean }) => {
    const [selectedUserOption, setSelectedUserOption] = useState<string | undefined>(undefined);

    const switchChange = (level: number, user: string, isOn: boolean) => {
      onCallChange(level, user, isOn);
    }

    const handleUserChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedOption = e.target.value;
      if (selectedOption !== "") {
        setSelectedUserOption(selectedOption);
        handleAddUserToChain(selectedOption, props.link);
        setSelectedUserOption(undefined); // Reset selection
      }
    }

    const onlyOneUser = Object.keys(props.link.userMap!).length == 1;

    const users = Object.keys(props.link.userMap!).map(key => {
      const user = props.link.userMap![key];
      return (
        <tr key={key}>
          <td>{user.firstName} {user.lastName}</td>
          <td>{user.username}</td>
          {!props.hideOnCall && <td>
          {props.editing ? <Switch checked={props.link.onCallMap![user.objectId!]} onChange={(checked) => switchChange(props.link.level!, user.objectId!, checked)} /> :
            props.link.onCallMap![user.objectId!] ? <div className={styles["onCall"]}>Yes</div> : <div className={styles["offCall"]}>No</div>}
          </td>}
          {props.editing && <td><Button size="s" variant="ghost" onClick={() => handleRemoveUserFromChain(props.link.level!, user.objectId!)} title="Remove" disabled={onlyOneUser && ! props.isLastLevel} /></td>}
        </tr>
      );
    });

    return (
      <div>
        <p style={{ marginBottom: 4, fontStyle: 'italic' }}><b>Level: {props.link.level}</b></p>
        <div style={{borderTop: '1px solid #ccc', marginTop: 4, marginBottom: 4}}></div>
        <table className={styles['chainTable']}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Username</th>
              {!props.hideOnCall && <th>On Call</th>}
              {props.editing && <th>Actions</th>}
            </tr>
          </thead>
          <tbody>
            {users}
            {props.editing &&
              <tr>
                <td>
                  <select 
                    name="user" 
                    id="user" 
                    onChange={(e) => handleUserChange(e)} 
                    value={selectedUserOption ?? ""}
                  >
                    <option value="">Add User..</option>
                    {availableUserOptions.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>}
          </tbody>
        </table>
        <div style={{borderTop: '1px solid #ccc', marginBottom: 14}}></div>
      </div>
    )
  }

  const links = props.chain?.links?.map(l => (
    <ChainLink key={l.level} link={l} editing={props.editing} users={props.users} isLastLevel={l.level == props.chain?.links!.length} hideOnCall={props.hideOnCall} />
  ));

  const highestLinkHasUsers = props.chain?.links?.length ? Object.keys(props.chain.links[props.chain.links.length - 1].userMap!).length > 0 : true;

  return (
    <div>
      {props.chain == null || props.chain.links?.length == 0 && !props.editing && <em>No notify chain for component</em>}
      {[
        links,
        props.editing &&
        <div style={{ display: 'flex', flexDirection: 'row', columnGap: 16 }}>
          <Button size="s" onClick={AddChainLink} title="+ Add Level" disabled={!highestLinkHasUsers} />
          <Button size="s" variant="secondary-dark" onClick={() => removeChainLink(props.chain!.links![props.chain!.links!.length - 1].level!)} title="- Remove Level" disabled={props.chain?.links?.length == 0} />
        </div>
      ]}
    </div>
  )
}

export default NotifyChainComponent;