// JSX Component
import styles from './ManageSchedulePage.module.scss';
import { useCallback, useEffect, useState } from 'react';
import { perDS } from '../../../datasource/WebDS';
import { ScheduleDetails, UpdateScheduleRequest } from '@perentie/common';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@systemic-design-framework/react';
import Select from 'react-select';
// import Select from 'react-select/dist/declarations/src/Select';

const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

function ManageSchedulePage() {
  const { scheduleId } = useParams();
  const [schedule, setSchedule] = useState<ScheduleDetails>();
  const [timeZones, setTimeZones] = useState<{ [key: string]: string }>({});
  const [timeZoneOptions, setTimeZoneOptions] = useState<{ value: string, label: string }[]>([]);
  const [selectedTimeZone, setSelectedTimeZone] = useState<string>();
  const [formValid, setFormValid] = useState(false);
  const navigate = useNavigate();

  const onCancel = useCallback(() => {
    navigate('/schedules');
  }, [navigate]);

  useEffect(() => {
    if (scheduleId && scheduleId !== 'new') {
      perDS.getSchedule(scheduleId).then(res => setSchedule(res)).catch(console.error);
    } else {
      setSchedule({
        _default: false,
        key: '',
        name: '',
        timeZone: undefined,
        objectId: 'new',
        timeSpans: [],
      });
    }
  }, [scheduleId]);

  useEffect(() => {
    perDS.getTimeZones().then(res => setTimeZones(res)).catch(console.error);
  }, []);

  useEffect(() => {
    setTimeZoneOptions(Object.keys(timeZones).map(key => ({ value: key, label: timeZones[key] })));
  }, [timeZones]);

  useEffect(() => {
    if (schedule?.timeZone) setSelectedTimeZone(schedule.timeZone);
  }, [schedule]);

  useEffect(() => {
    setFormValid(!!schedule?.key && (schedule?.timeSpans?.length ?? 0) > 0);
  }, [schedule]);

  const handleTimeSpanChange = (index: number, field: string, value: string) => {
    const updatedTimeSpans = schedule?.timeSpans?.map((ts, i) => {
      if (i === index) {
        const [hour, minute, second] = value.split(':').map(Number);
        return { ...ts, [field]: { hour, minute, second } };
      }
      return ts;
    });
    setSchedule({ ...schedule, timeSpans: updatedTimeSpans });
  };

  const handleDayMaskChange = (index: number, dayIndex: number) => {
    const updatedTimeSpans = schedule?.timeSpans?.map((ts, i) => {
      if (i === index) {
        const dayMask = (ts.dayMask ?? 0) ^ (1 << dayIndex);
        return { ...ts, dayMask };
      }
      return ts;
    });
    setSchedule({ ...schedule, timeSpans: updatedTimeSpans });
  };

  const onSave = () => {
    const updatedRequest: UpdateScheduleRequest = {
      key: schedule?.key,
      name: schedule?.name,
      timeZone: schedule?.timeZone,
      timeSpans: schedule?.timeSpans,
    };
    if (schedule?.objectId === 'new') {
      perDS.createSchedule(updatedRequest)
      .then((res) => {
        console.log('createSchedule', res);
        navigate('/schedules')
      })
      .catch(console.error);
    } else {
      perDS.updateSchedule(schedule?.objectId ?? '', updatedRequest)
      .then((res) => {
        console.log('updateSchedule', res);
        navigate('/schedules')
      })
      .catch(console.error);
    }
  };

  const addTimeSpan = () => {
    setSchedule({
      ...schedule,
      timeSpans: [...(schedule?.timeSpans ?? []), { dayMask: 0, start: { hour: 0, minute: 0, second: 0 }, end: { hour: 0, minute: 0, second: 0 } }],
    });
  };

  const removeTimeSpan = (index: number) => {
    const updatedTimeSpans = schedule?.timeSpans?.filter((_, i) => i !== index);
    setSchedule({ ...schedule, timeSpans: updatedTimeSpans });
  };

  return (
    <div className={styles.content}>
      <h2>{schedule?.objectId !== 'new' ? 'Edit Schedule' : 'Create Schedule'}</h2>
      <div className={styles.form}>
        <div className={styles.row}>
          <label>Name</label>
          <input type="text" value={schedule?.name} onChange={(e) => setSchedule({ ...schedule, name: e.target.value })} />
        </div>
        <div className={styles.row}>
          <label>Key</label>
          <input type="text" value={schedule?.key} onChange={(e) => setSchedule({ ...schedule, key: e.target.value })} disabled={schedule?._default} />
        </div>
        <div className={styles.row}>
          <label>Time Spans</label>
          <div className={styles.timeSpansContainer}>
            {schedule?.timeSpans?.map((ts, index) => (
              <div key={index} className={styles.timeSpan}>
                <Button size='s' variant='secondary-dark' title="Remove" onClick={() => removeTimeSpan(index)} />
                  <div className={styles.row}>
                    <label>Days</label>
                    <div className={styles.daysContainer}>
                      {daysOfWeek.map((day, dayIndex) => (
                        <label key={dayIndex}>
                          <input
                            type="checkbox"
                            checked={((ts.dayMask ?? 0) & (1 << dayIndex)) !== 0}
                            onChange={() => handleDayMaskChange(index, dayIndex)}
                          />
                          {day}
                        </label>
                      ))}
                    </div>
                  </div>
                <div className={styles.row}>
                  <label>Start</label>
                  <input
                    type="time"
                    value={`${ts.start?.hour!.toString().padStart(2, '0')}:${ts.start?.minute!.toString().padStart(2, '0')}:${ts.start?.second?.toString().padStart(2, '0') ?? '00'}`}
                    onChange={(e) => handleTimeSpanChange(index, 'start', e.target.value)}
                    step={1}
                  />
                </div>
                <div className={styles.row}>
                  <label>End</label>
                  <input
                    type="time"
                    value={`${ts.end?.hour!.toString().padStart(2, '0')}:${ts.end?.minute!.toString().padStart(2, '0')}:${ts.end?.second?.toString().padStart(2, '0') ?? '00'}`}
                    onChange={(e) => handleTimeSpanChange(index, 'end', e.target.value)}
                    step={1}
                  />
                </div>
              </div>
            ))}
            <div className={styles.addTimeSpan}>
              <Button size='s' title="Add Time Span" onClick={addTimeSpan} />
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <label>Timezone</label>
          <Select
            value={selectedTimeZone ? { value: selectedTimeZone, label: timeZones[selectedTimeZone] } : null}
            onChange={(selected: any) => {
              setSelectedTimeZone(selected?.value);
              setSchedule({ ...schedule, timeZone: selected?.value });
            }}
            options={timeZoneOptions}
            isClearable
            isDisabled={schedule?._default}
          />
        </div>
      </div>
      <div className={styles.footer}>
        <Button size='s' variant='secondary-dark' title="Cancel" onClick={onCancel} />
        <Button size='s' title="Save" onClick={onSave} disabled={!formValid} />
      </div>
    </div>
  );
}

export default ManageSchedulePage;
