import { useEffect, useState } from 'react';
import {
    IoCalendarOutline,
    IoCaretForward,
    IoCodeSlash,
    IoDocumentOutline,
    IoExtensionPuzzleOutline,
    IoGridOutline,
    IoHandRightOutline, IoIdCardOutline,
    IoList,
    IoNotificationsOutline,
    IoPeopleOutline, IoUmbrellaOutline
} from 'react-icons/io5';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { AuthenticationContext, LoginGateway } from 'systemic-auth-react';

import { Header, Link, Section, Sidebar } from '@systemic-design-framework/react';

import { ReactComponent as ManageAgentsIcon } from './assets/manage-agents.svg';
import BottomBar from './components/BottomBar/BottomBar';
import CurrentUser from './components/Icons/CurrentUser';
import ScreenIdentityBar from './components/ScreenIdentityBar/ScreenIdentityBar';
import TopBar from './components/TopBar/TopBar';
import useDispatch from './hooks/useDispatch';
import useSelector from './hooks/useSelector';
import AgentDetailsContainer from './pages/AgentDetailsPage/AgentDetailsContainer';
import AgentsPage from './pages/AgentsPage/AgentsPage';
import FallbackPage from './pages/FallbackPage/FallbackPage';
import LoginPage from './pages/LoginPage/LoginPage';
import RegisterAgentPage from './pages/RegisterAgentPage/RegisterAgentPage';
import {
    selectIsAuthenticated,
    selectToken,
    selectUser,
    setAuthenticated,
    setUserDetails
} from './redux/authenticationSlice';
import { selectAPIUrl, setAPI } from './redux/devSlice';
import ComponentsListContainer from "./pages/ManageConfigPage/ComponentsListContainer";
import {perDS} from "./datasource/WebDS";
import ActiveAlertsPage from "./pages/ActiveAlertsPage/ActiveAlertsPage";
import AlertHistoryPage from "./pages/AlertHistoryPage/AlertHistoryPage";
import ManageComponentsPage from "./pages/ManageComponentsPage/ManageComponentsPage";
import ManageUsersPage from "./pages/ManageUsersPage/ManageUsersPage";
import ManageSchedulesPage from "./pages/ManageSchedulesPage/ManageSchedulesPage";
import CreateComponentPage from "./pages/CreateComponentPage/CreateComponentPage";
import AlertDetailsPage from "./pages/AlertDetailsPage/AlertDetailsPage";
import ManageClientsPage from "./pages/ManageClientsPage/ManageClientsPage";
import ManageRulesPage from './pages/ManageRulesPage/ManageRulesPage';
import AddClientsPage from './pages/ManageClientsPage/AddClientsPage/AddClientsPage';
import ManageClientPage from './pages/ManageClientsPage/ManageClientPage/ManageClientPage';
import ManageRostersPage from './pages/ManageRostersPage/ManageRostersPage';
import ManageRosterPage from './pages/ManageRostersPage/ManageRosterPage/ManageRosterPage';
import {AccessUtil, UserRoleDetailsNameEnum} from '@perentie/common';
import ManageReportsPage from './pages/ManageReportsPage/ManageReportsPage';
import ManageSchedulePage from './pages/ManageSchedulesPage/ManageSchedulePage/ManageSchedulePage';

function AppWrapper() {
    const { REACT_APP_VERSION, REACT_APP_MODE, REACT_APP_API_URL } = process.env;
    console.info(`App version: ${REACT_APP_VERSION}`);
    console.info(`App mode: ${REACT_APP_MODE}`);
    console.info(`API URL: ${REACT_APP_API_URL}`);
    const [checkingSession, setCheckingSession] = useState(true);

    const dispatch = useDispatch();
    const token = useSelector(selectToken);
    const apiUrl = useSelector(selectAPIUrl);
    const authenticated = useSelector(selectIsAuthenticated);

    useEffect(() => {
        perDS.checkSession().then(res => {
            dispatch(setUserDetails(res.userDetails!));
            console.log(' checkSession res:', res);
            perDS.setUserToken(res.authToken!);
            dispatch(setAuthenticated(true));
            setCheckingSession(false);
        }).catch(reason => {
            dispatch(setUserDetails({}));
            console.log(' checkSession catch', reason);
            perDS.setUserToken('');
            dispatch(setAuthenticated(false));
            setCheckingSession(false);
        })
    }, []);


    return (<>
      {(checkingSession) ? <div>loading..</div> :
        <LoginGateway
          authenticated={authenticated}
          config={{
              google: {
                  enabled: true,
                  auto: false,
                  clientId: '207461263293-6o7vefkeknltkfjp9mgdq86spgvlpmhs.apps.googleusercontent.com',
              },
          }}
          onLoginReducer={(action, payload) => {
              console.log('onLoginReducer', action, payload);
              console.log('LoginReducer settoken');
              perDS.setUserToken(payload.token!);
              dispatch(setAuthenticated(true));
              perDS.checkSession().then(res => {
                console.log(res);
                dispatch(setUserDetails(res.userDetails!));
              })
              // dispatch(setToken(payload.token));
              // checkSessionHandler();
          }}
          LoginComponent={<LoginPage/>}
        >
            <App/>
        </LoginGateway>
        }
        </>
    );
}

function App() {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const token = useSelector(selectToken);

    const user = useSelector(selectUser);
    const accessUtil = new AccessUtil(user);
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
    const [headerHeight, setHeaderHeight] = useState<number>();

    const [establishedSession, setEstablishedSession] = useState(false);
    const logoutClick = () => {
        perDS.logoutUser();
        dispatch(setAuthenticated(false));
        navigate("/");
    }

    /*
    const authContext = useContext(AuthenticationContext);

    useEffect(() => {
        if (!!authContext.token && !establishedSession) {
            console.log('USER IS ', authContext.user);
            const authToken = token;
            const newAuthToken = !!authContext.token ? authContext.token : null;
            if (newAuthToken !== authToken) {
                console.log('NEW AUTH TOKEN', newAuthToken);
                dispatch(setToken(authContext.token));
                dispatch(checkSession());
            }
        } else {
            dispatch(setToken(''));
        }
    });
    */
    return (
        <div className="app-container">
            <div style={{ display: 'flex' }}>
                <Sidebar navigator={navigate} location={location} collapsed={sidebarCollapsed} width={250}>
                    <Header Icon={CurrentUser} height={headerHeight}>
                    </Header>

                    <Section>
                        <Link title="Dashboard" to="/dashboard" ActiveIcon={IoCaretForward} Icon={IoGridOutline} />
                    </Section>

                    <Section title="Alerts" separator>
                        <Link
                            title="Manage Alerts"
                            to="/alerts"
                            Icon={IoNotificationsOutline}
                            ActiveIcon={IoCaretForward}
                        />
                        <Link title="Alert History" Icon={IoList} to="/history" ActiveIcon={IoCaretForward} />
                    </Section>
                    {accessUtil.isSystemAdmin() && (
                        <Section title="Administration" separator>
                            <Link title="Manage Agents" to="/agents" ActiveIcon={IoCaretForward} Icon={ManageAgentsIcon} />
                            <Link
                                title="Manage Config"
                                Icon={IoCodeSlash}
                                ActiveIcon={IoCaretForward}
                                to="/config"
                            />

                            <Link
                            title="Manage Components"
                            Icon={IoExtensionPuzzleOutline}
                            ActiveIcon={IoCaretForward}
                            to="/components"
                            />

                            <Link title="Manage Users" Icon={IoPeopleOutline} ActiveIcon={IoCaretForward} to="/users" />
                            <Link
                                title="Manage Schedules"
                                Icon={IoCalendarOutline}
                                ActiveIcon={IoCaretForward}
                                to="/schedules"
                            />
                            <Link title="Manage Clients" Icon={IoUmbrellaOutline} ActiveIcon={IoCaretForward} to="/clients" />
                            <Link title="Manage Rules" Icon={IoHandRightOutline} ActiveIcon={IoCaretForward} to="/rules" />
                            <Link
                            title="Manage Reports"
                            Icon={IoDocumentOutline}
                            ActiveIcon={IoCaretForward}
                            to="/reports"
                            />

                            <Link title="Manage Rosters" Icon={IoIdCardOutline} ActiveIcon={IoCaretForward} to="/rosters" />
                        </Section>
                    )}

                    {(accessUtil.hasRoleType(UserRoleDetailsNameEnum.ClientAdmin) && !accessUtil.isSystemAdmin()) && (
                        <Section title="Administration" separator>
                            <Link
                            title="Manage Components"
                            Icon={IoExtensionPuzzleOutline}
                            ActiveIcon={IoCaretForward}
                            to="/components"
                            />
                            <Link title="Manage Rules" Icon={IoHandRightOutline} ActiveIcon={IoCaretForward} to="/rules" />
                        </Section>
                    )}
                    <Section title="" separator>
                        <button onClick={logoutClick}>Logout</button>
                    </Section>
                </Sidebar>
            </div>
            <div className="page-container">
                <TopBar collapsed={sidebarCollapsed} setCollapsed={setSidebarCollapsed} setHeight={setHeaderHeight} />
                <ScreenIdentityBar />
                <Routes>
                    <Route path="/">
                        <Route path="dashboard" element={<FallbackPage />} />

                        <Route path="history" element={<AlertHistoryPage />} />
                        <Route path="alerts" element={<ActiveAlertsPage />} />
                        <Route path="alert/:alertId" element={<AlertDetailsPage />} />

                        <Route path="schedules" element={<ManageSchedulesPage />} />
                        <Route path="schedules/:scheduleId" element={<ManageSchedulePage />} />

                        <Route path="users" element={<ManageUsersPage />} />

                        <Route path="reports" element={<ManageReportsPage />} />

                        <Route path="rules" element={<ManageRulesPage />} />

                        <Route path="clients" element={<ManageClientsPage />} />
                        <Route path="clients/add" element={<AddClientsPage />} />
                        <Route path="clients/details/:clientId" element={<ManageClientPage />} />

                        <Route path="components" element={<ManageComponentsPage />} />
                        <Route path="components/create" element={<CreateComponentPage />} />

                        <Route path="config" element={<ComponentsListContainer />} />

                        <Route path="agents/details" element={<AgentDetailsContainer />} />
                        <Route path="agents/register" element={<RegisterAgentPage />} />
                        <Route path="agents" element={<AgentsPage />} />

                        <Route path="rosters" element={<ManageRostersPage />} />
                        <Route path="rosters/details/:rosterId" element={<ManageRosterPage />} />
                        <Route path="debug" element={<FallbackPage />} />
                    </Route>
                </Routes>
                <BottomBar />
            </div>
        </div>
    );
}

export default AppWrapper;
