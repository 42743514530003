import React, { useState, ReactNode, CSSProperties } from "react";
import styles from "./ButtonGroup.module.scss"

interface ButtonGroup {
  label: string;
  icon?: ReactNode;
  onClick: () => void;
}

interface ButtonGroupProps {
  groups: ButtonGroup[];
  defaultIndex?: number;
}

const ButtonGroup: React.FC<ButtonGroupProps> = ({ groups, defaultIndex = 0 }) => {
  const [selectedButton, setSelectedButton] = useState(defaultIndex);
  
  const handleButtonClick = (index: number) => {
    setSelectedButton(index);
    groups[index].onClick();
  };

  return (
    <>
      {groups.length > 0 && (
        <div className={styles["group-container"]}>
          {groups.map((group, idx) => {
            return (
              <button 
                key={idx}
                className={`${styles["perentie-button"]} ${selectedButton == idx ? styles["selected"] : ''}`} onClick={() => {handleButtonClick(idx)}}>
                <span style={{display: 'flex', alignItems: 'center', gap: 4}}>{group?.icon} {group.label}</span>
              </button>
            )
          })}
        </div>
      )}
    </>
  );
};

export default ButtonGroup;
