import React, { useState, ReactNode, CSSProperties } from "react";
import styles from "./Tabs.module.scss"

interface TabProps {
  label: ReactNode;
  labelStyle?: CSSProperties
  content: ReactNode;
  contentStyle?: CSSProperties;
}

interface TabsProps {
  tabs: TabProps[];
  defaultSelected?: number;
  style?: CSSProperties;
}

const Tabs: React.FC<TabsProps> = ({ tabs, defaultSelected = 0, style = {height: '100%'} }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(defaultSelected);

  const handleTabClick = (index: number) => {
    setActiveTabIndex(index);
  };

  return (
    <div className={styles["tabs"]} style={style}>

      <div className={styles["tab-headers"]}>
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`${index == activeTabIndex ? styles["active"] : ""}`}
            onClick={() => handleTabClick(index)}
          >
            <span style={tab.labelStyle} className={styles['text']}>{tab.label}</span>
          </button>
        ))}
      </div>

      <div className={styles["tab-content"]} style={tabs[activeTabIndex]?.contentStyle}>
        {tabs[activeTabIndex]?.content}
      </div>
    </div>
  );
};

export default Tabs;
