/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ClientBasicDetails } from './ClientBasicDetails';
import {
    ClientBasicDetailsFromJSON,
    ClientBasicDetailsFromJSONTyped,
    ClientBasicDetailsToJSON,
} from './ClientBasicDetails';
import type { EventLogItem } from './EventLogItem';
import {
    EventLogItemFromJSON,
    EventLogItemFromJSONTyped,
    EventLogItemToJSON,
} from './EventLogItem';
import type { NotifyChainDetails } from './NotifyChainDetails';
import {
    NotifyChainDetailsFromJSON,
    NotifyChainDetailsFromJSONTyped,
    NotifyChainDetailsToJSON,
} from './NotifyChainDetails';
import type { RuleBasicDetails } from './RuleBasicDetails';
import {
    RuleBasicDetailsFromJSON,
    RuleBasicDetailsFromJSONTyped,
    RuleBasicDetailsToJSON,
} from './RuleBasicDetails';
import type { UserBasicDetails } from './UserBasicDetails';
import {
    UserBasicDetailsFromJSON,
    UserBasicDetailsFromJSONTyped,
    UserBasicDetailsToJSON,
} from './UserBasicDetails';

/**
 * 
 * @export
 * @interface AlertDetails
 */
export interface AlertDetails {
    /**
     * 
     * @type {string}
     * @memberof AlertDetails
     */
    objectId?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertDetails
     */
    componentId?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertDetails
     */
    componentState?: AlertDetailsComponentStateEnum;
    /**
     * 
     * @type {string}
     * @memberof AlertDetails
     */
    componentName?: string;
    /**
     * 
     * @type {Array<ClientBasicDetails>}
     * @memberof AlertDetails
     */
    clients?: Array<ClientBasicDetails>;
    /**
     * 
     * @type {Date}
     * @memberof AlertDetails
     */
    created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AlertDetails
     */
    ended?: Date;
    /**
     * 
     * @type {string}
     * @memberof AlertDetails
     */
    status?: AlertDetailsStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AlertDetails
     */
    statusDisplay?: string;
    /**
     * 
     * @type {Array<EventLogItem>}
     * @memberof AlertDetails
     */
    eventLog?: Array<EventLogItem>;
    /**
     * 
     * @type {{ [key: string]: UserBasicDetails; }}
     * @memberof AlertDetails
     */
    actionUsers?: { [key: string]: UserBasicDetails; };
    /**
     * 
     * @type {UserBasicDetails}
     * @memberof AlertDetails
     */
    handlingUser?: UserBasicDetails;
    /**
     * 
     * @type {string}
     * @memberof AlertDetails
     */
    agentName?: string;
    /**
     * 
     * @type {RuleBasicDetails}
     * @memberof AlertDetails
     */
    lastRule?: RuleBasicDetails;
    /**
     * 
     * @type {NotifyChainDetails}
     * @memberof AlertDetails
     */
    currentNotifyChain?: NotifyChainDetails;
    /**
     * 
     * @type {Array<string>}
     * @memberof AlertDetails
     */
    notifiedUsers?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AlertDetails
     */
    supportMinutes?: number;
    /**
     * 
     * @type {string}
     * @memberof AlertDetails
     */
    reference?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertDetails
     */
    clientReference?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertDetails
     */
    closeSummary?: string;
}


/**
 * @export
 */
export const AlertDetailsComponentStateEnum = {
    Unknown: 'UNKNOWN',
    Ok: 'OK',
    Info: 'INFO',
    Warning: 'WARNING',
    Severe: 'SEVERE',
    Critical: 'CRITICAL'
} as const;
export type AlertDetailsComponentStateEnum = typeof AlertDetailsComponentStateEnum[keyof typeof AlertDetailsComponentStateEnum];

/**
 * @export
 */
export const AlertDetailsStatusEnum = {
    ActiveOpen: 'ACTIVE_OPEN',
    NotActiveOpen: 'NOT_ACTIVE_OPEN',
    Closed: 'CLOSED'
} as const;
export type AlertDetailsStatusEnum = typeof AlertDetailsStatusEnum[keyof typeof AlertDetailsStatusEnum];


/**
 * Check if a given object implements the AlertDetails interface.
 */
export function instanceOfAlertDetails(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AlertDetailsFromJSON(json: any): AlertDetails {
    return AlertDetailsFromJSONTyped(json, false);
}

export function AlertDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'objectId': !exists(json, 'objectId') ? undefined : json['objectId'],
        'componentId': !exists(json, 'component_id') ? undefined : json['component_id'],
        'componentState': !exists(json, 'componentState') ? undefined : json['componentState'],
        'componentName': !exists(json, 'componentName') ? undefined : json['componentName'],
        'clients': !exists(json, 'clients') ? undefined : ((json['clients'] as Array<any>).map(ClientBasicDetailsFromJSON)),
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'ended': !exists(json, 'ended') ? undefined : (new Date(json['ended'])),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'statusDisplay': !exists(json, 'statusDisplay') ? undefined : json['statusDisplay'],
        'eventLog': !exists(json, 'eventLog') ? undefined : ((json['eventLog'] as Array<any>).map(EventLogItemFromJSON)),
        'actionUsers': !exists(json, 'actionUsers') ? undefined : (mapValues(json['actionUsers'], UserBasicDetailsFromJSON)),
        'handlingUser': !exists(json, 'handlingUser') ? undefined : UserBasicDetailsFromJSON(json['handlingUser']),
        'agentName': !exists(json, 'agentName') ? undefined : json['agentName'],
        'lastRule': !exists(json, 'lastRule') ? undefined : RuleBasicDetailsFromJSON(json['lastRule']),
        'currentNotifyChain': !exists(json, 'currentNotifyChain') ? undefined : NotifyChainDetailsFromJSON(json['currentNotifyChain']),
        'notifiedUsers': !exists(json, 'notifiedUsers') ? undefined : json['notifiedUsers'],
        'supportMinutes': !exists(json, 'supportMinutes') ? undefined : json['supportMinutes'],
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
        'clientReference': !exists(json, 'clientReference') ? undefined : json['clientReference'],
        'closeSummary': !exists(json, 'closeSummary') ? undefined : json['closeSummary'],
    };
}

export function AlertDetailsToJSON(value?: AlertDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'objectId': value.objectId,
        'component_id': value.componentId,
        'componentState': value.componentState,
        'componentName': value.componentName,
        'clients': value.clients === undefined ? undefined : ((value.clients as Array<any>).map(ClientBasicDetailsToJSON)),
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'ended': value.ended === undefined ? undefined : (value.ended.toISOString()),
        'status': value.status,
        'statusDisplay': value.statusDisplay,
        'eventLog': value.eventLog === undefined ? undefined : ((value.eventLog as Array<any>).map(EventLogItemToJSON)),
        'actionUsers': value.actionUsers === undefined ? undefined : (mapValues(value.actionUsers, UserBasicDetailsToJSON)),
        'handlingUser': UserBasicDetailsToJSON(value.handlingUser),
        'agentName': value.agentName,
        'lastRule': RuleBasicDetailsToJSON(value.lastRule),
        'currentNotifyChain': NotifyChainDetailsToJSON(value.currentNotifyChain),
        'notifiedUsers': value.notifiedUsers,
        'supportMinutes': value.supportMinutes,
        'reference': value.reference,
        'clientReference': value.clientReference,
        'closeSummary': value.closeSummary,
    };
}

