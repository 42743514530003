/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PerentieAlertAction
 */
export interface PerentieAlertAction {
    /**
     * 
     * @type {string}
     * @memberof PerentieAlertAction
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PerentieAlertAction
     */
    type?: PerentieAlertActionTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof PerentieAlertAction
     */
    timeStamp?: Date;
    /**
     * 
     * @type {string}
     * @memberof PerentieAlertAction
     */
    alertId?: string;
    /**
     * 
     * @type {string}
     * @memberof PerentieAlertAction
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof PerentieAlertAction
     */
    targetUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof PerentieAlertAction
     */
    info?: string;
    /**
     * 
     * @type {Date}
     * @memberof PerentieAlertAction
     */
    startTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PerentieAlertAction
     */
    endTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof PerentieAlertAction
     */
    componentState?: PerentieAlertActionComponentStateEnum;
}


/**
 * @export
 */
export const PerentieAlertActionTypeEnum = {
    Handle: 'HANDLE',
    Unhandle: 'UNHANDLE',
    Assign: 'ASSIGN',
    Comment: 'COMMENT',
    Notify: 'NOTIFY',
    NotifyFail: 'NOTIFY_FAIL',
    UserClose: 'USER_CLOSE',
    SupportTime: 'SUPPORT_TIME'
} as const;
export type PerentieAlertActionTypeEnum = typeof PerentieAlertActionTypeEnum[keyof typeof PerentieAlertActionTypeEnum];

/**
 * @export
 */
export const PerentieAlertActionComponentStateEnum = {
    Unknown: 'UNKNOWN',
    Ok: 'OK',
    Info: 'INFO',
    Warning: 'WARNING',
    Severe: 'SEVERE',
    Critical: 'CRITICAL'
} as const;
export type PerentieAlertActionComponentStateEnum = typeof PerentieAlertActionComponentStateEnum[keyof typeof PerentieAlertActionComponentStateEnum];


/**
 * Check if a given object implements the PerentieAlertAction interface.
 */
export function instanceOfPerentieAlertAction(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PerentieAlertActionFromJSON(json: any): PerentieAlertAction {
    return PerentieAlertActionFromJSONTyped(json, false);
}

export function PerentieAlertActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): PerentieAlertAction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'timeStamp': !exists(json, 'timeStamp') ? undefined : (new Date(json['timeStamp'])),
        'alertId': !exists(json, 'alert_id') ? undefined : json['alert_id'],
        'userId': !exists(json, 'user_id') ? undefined : json['user_id'],
        'targetUserId': !exists(json, 'targetUser_id') ? undefined : json['targetUser_id'],
        'info': !exists(json, 'info') ? undefined : json['info'],
        'startTime': !exists(json, 'startTime') ? undefined : (new Date(json['startTime'])),
        'endTime': !exists(json, 'endTime') ? undefined : (new Date(json['endTime'])),
        'componentState': !exists(json, 'componentState') ? undefined : json['componentState'],
    };
}

export function PerentieAlertActionToJSON(value?: PerentieAlertAction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'timeStamp': value.timeStamp === undefined ? undefined : (value.timeStamp.toISOString()),
        'alert_id': value.alertId,
        'user_id': value.userId,
        'targetUser_id': value.targetUserId,
        'info': value.info,
        'startTime': value.startTime === undefined ? undefined : (value.startTime.toISOString()),
        'endTime': value.endTime === undefined ? undefined : (value.endTime.toISOString()),
        'componentState': value.componentState,
    };
}

