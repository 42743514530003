/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AlertActionRequest,
  RESTResultAlertDetails,
  RESTResultListActiveEventDetails,
  RESTResultListAlertBasicDetails,
  RESTResultListStateHistoryDetails,
  RESTResultPerentieAlertAction,
  UpdateAlertRequest,
} from '../models/index';
import {
    AlertActionRequestFromJSON,
    AlertActionRequestToJSON,
    RESTResultAlertDetailsFromJSON,
    RESTResultAlertDetailsToJSON,
    RESTResultListActiveEventDetailsFromJSON,
    RESTResultListActiveEventDetailsToJSON,
    RESTResultListAlertBasicDetailsFromJSON,
    RESTResultListAlertBasicDetailsToJSON,
    RESTResultListStateHistoryDetailsFromJSON,
    RESTResultListStateHistoryDetailsToJSON,
    RESTResultPerentieAlertActionFromJSON,
    RESTResultPerentieAlertActionToJSON,
    UpdateAlertRequestFromJSON,
    UpdateAlertRequestToJSON,
} from '../models/index';

export interface AddCommentToAlertRequest {
    alertId: string;
    alertActionRequest?: AlertActionRequest;
}

export interface AddOrUpdateActionToAlertRequest {
    alertId: string;
    alertActionRequest?: AlertActionRequest;
}

export interface CloseAlertRequest {
    alertId: string;
    updateAlertRequest?: UpdateAlertRequest;
}

export interface GetAlertActionRequest {
    objectId: string;
}

export interface GetAlertDetailsRequest {
    alertId: string;
}

export interface GetAlertEventSummaryRequest {
    alertId: string;
}

export interface GetComponentStateHistoryForAlertRequest {
    alertId: string;
}

export interface GetRecentAlertsRequest {
    num?: number;
}

export interface UpdateAlertClientReferenceRequest {
    alertId: string;
    updateAlertRequest?: UpdateAlertRequest;
}

export interface UpdateAlertCloseSummaryRequest {
    alertId: string;
    updateAlertRequest?: UpdateAlertRequest;
}

/**
 * PerentieAlertsWSApi - interface
 * 
 * @export
 * @interface PerentieAlertsWSApiInterface
 */
export interface PerentieAlertsWSApiInterface {
    /**
     * Add Comment to an Alert
     * @param {string} alertId 
     * @param {AlertActionRequest} [alertActionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieAlertsWSApiInterface
     */
    addCommentToAlertRaw(requestParameters: AddCommentToAlertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultAlertDetails>>;

    /**
     * Add Comment to an Alert
     */
    addCommentToAlert(alertId: string, alertActionRequest?: AlertActionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultAlertDetails>;

    /**
     * Add Support time to an Alert
     * @param {string} alertId 
     * @param {AlertActionRequest} [alertActionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieAlertsWSApiInterface
     */
    addOrUpdateActionToAlertRaw(requestParameters: AddOrUpdateActionToAlertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultAlertDetails>>;

    /**
     * Add Support time to an Alert
     */
    addOrUpdateActionToAlert(alertId: string, alertActionRequest?: AlertActionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultAlertDetails>;

    /**
     * Manually Close an alert
     * @param {string} alertId 
     * @param {UpdateAlertRequest} [updateAlertRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieAlertsWSApiInterface
     */
    closeAlertRaw(requestParameters: CloseAlertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultAlertDetails>>;

    /**
     * Manually Close an alert
     */
    closeAlert(alertId: string, updateAlertRequest?: UpdateAlertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultAlertDetails>;

    /**
     * Get active alerts for logged in user. This is only alerts that don\'t have an ended date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieAlertsWSApiInterface
     */
    getActiveAlertsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultListAlertBasicDetails>>;

    /**
     * Get active alerts for logged in user. This is only alerts that don\'t have an ended date
     */
    getActiveAlerts(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultListAlertBasicDetails>;

    /**
     * Get alert action
     * @param {string} objectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieAlertsWSApiInterface
     */
    getAlertActionRaw(requestParameters: GetAlertActionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultPerentieAlertAction>>;

    /**
     * Get alert action
     */
    getAlertAction(objectId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultPerentieAlertAction>;

    /**
     * Get alert details
     * @param {string} alertId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieAlertsWSApiInterface
     */
    getAlertDetailsRaw(requestParameters: GetAlertDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultAlertDetails>>;

    /**
     * Get alert details
     */
    getAlertDetails(alertId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultAlertDetails>;

    /**
     * Get a Summary of the active events for an alert
     * @param {string} alertId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieAlertsWSApiInterface
     */
    getAlertEventSummaryRaw(requestParameters: GetAlertEventSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultListActiveEventDetails>>;

    /**
     * Get a Summary of the active events for an alert
     */
    getAlertEventSummary(alertId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultListActiveEventDetails>;

    /**
     * Get the component state history of component for alert
     * @param {string} alertId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieAlertsWSApiInterface
     */
    getComponentStateHistoryForAlertRaw(requestParameters: GetComponentStateHistoryForAlertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultListStateHistoryDetails>>;

    /**
     * Get the component state history of component for alert
     */
    getComponentStateHistoryForAlert(alertId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultListStateHistoryDetails>;

    /**
     * Get alerts that are active (active means enddate is null) or have status NOT_ACTIVE_OPEN (sticky alerts). A component may have more than one alert with status NOT_ACTIVE_OPEN.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieAlertsWSApiInterface
     */
    getOpenAlertsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultListAlertBasicDetails>>;

    /**
     * Get alerts that are active (active means enddate is null) or have status NOT_ACTIVE_OPEN (sticky alerts). A component may have more than one alert with status NOT_ACTIVE_OPEN.
     */
    getOpenAlerts(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultListAlertBasicDetails>;

    /**
     * Get recently ended alerts
     * @param {number} [num] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieAlertsWSApiInterface
     */
    getRecentAlertsRaw(requestParameters: GetRecentAlertsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultListAlertBasicDetails>>;

    /**
     * Get recently ended alerts
     */
    getRecentAlerts(num?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultListAlertBasicDetails>;

    /**
     * Update alert client reference
     * @param {string} alertId 
     * @param {UpdateAlertRequest} [updateAlertRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieAlertsWSApiInterface
     */
    updateAlertClientReferenceRaw(requestParameters: UpdateAlertClientReferenceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultAlertDetails>>;

    /**
     * Update alert client reference
     */
    updateAlertClientReference(alertId: string, updateAlertRequest?: UpdateAlertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultAlertDetails>;

    /**
     * Update alert client reference
     * @param {string} alertId 
     * @param {UpdateAlertRequest} [updateAlertRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieAlertsWSApiInterface
     */
    updateAlertCloseSummaryRaw(requestParameters: UpdateAlertCloseSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultAlertDetails>>;

    /**
     * Update alert client reference
     */
    updateAlertCloseSummary(alertId: string, updateAlertRequest?: UpdateAlertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultAlertDetails>;

}

/**
 * 
 */
export class PerentieAlertsWSApi extends runtime.BaseAPI implements PerentieAlertsWSApiInterface {

    /**
     * Add Comment to an Alert
     */
    async addCommentToAlertRaw(requestParameters: AddCommentToAlertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultAlertDetails>> {
        if (requestParameters.alertId === null || requestParameters.alertId === undefined) {
            throw new runtime.RequiredError('alertId','Required parameter requestParameters.alertId was null or undefined when calling addCommentToAlert.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/alerts/{alert_id}/comment`.replace(`{${"alert_id"}}`, encodeURIComponent(String(requestParameters.alertId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AlertActionRequestToJSON(requestParameters.alertActionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultAlertDetailsFromJSON(jsonValue));
    }

    /**
     * Add Comment to an Alert
     */
    async addCommentToAlert(alertId: string, alertActionRequest?: AlertActionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultAlertDetails> {
        const response = await this.addCommentToAlertRaw({ alertId: alertId, alertActionRequest: alertActionRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Add Support time to an Alert
     */
    async addOrUpdateActionToAlertRaw(requestParameters: AddOrUpdateActionToAlertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultAlertDetails>> {
        if (requestParameters.alertId === null || requestParameters.alertId === undefined) {
            throw new runtime.RequiredError('alertId','Required parameter requestParameters.alertId was null or undefined when calling addOrUpdateActionToAlert.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/alerts/{alert_id}/action`.replace(`{${"alert_id"}}`, encodeURIComponent(String(requestParameters.alertId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AlertActionRequestToJSON(requestParameters.alertActionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultAlertDetailsFromJSON(jsonValue));
    }

    /**
     * Add Support time to an Alert
     */
    async addOrUpdateActionToAlert(alertId: string, alertActionRequest?: AlertActionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultAlertDetails> {
        const response = await this.addOrUpdateActionToAlertRaw({ alertId: alertId, alertActionRequest: alertActionRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Manually Close an alert
     */
    async closeAlertRaw(requestParameters: CloseAlertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultAlertDetails>> {
        if (requestParameters.alertId === null || requestParameters.alertId === undefined) {
            throw new runtime.RequiredError('alertId','Required parameter requestParameters.alertId was null or undefined when calling closeAlert.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/alerts/{alert_id}/close`.replace(`{${"alert_id"}}`, encodeURIComponent(String(requestParameters.alertId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateAlertRequestToJSON(requestParameters.updateAlertRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultAlertDetailsFromJSON(jsonValue));
    }

    /**
     * Manually Close an alert
     */
    async closeAlert(alertId: string, updateAlertRequest?: UpdateAlertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultAlertDetails> {
        const response = await this.closeAlertRaw({ alertId: alertId, updateAlertRequest: updateAlertRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Get active alerts for logged in user. This is only alerts that don\'t have an ended date
     */
    async getActiveAlertsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultListAlertBasicDetails>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/alerts/active`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultListAlertBasicDetailsFromJSON(jsonValue));
    }

    /**
     * Get active alerts for logged in user. This is only alerts that don\'t have an ended date
     */
    async getActiveAlerts(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultListAlertBasicDetails> {
        const response = await this.getActiveAlertsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get alert action
     */
    async getAlertActionRaw(requestParameters: GetAlertActionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultPerentieAlertAction>> {
        if (requestParameters.objectId === null || requestParameters.objectId === undefined) {
            throw new runtime.RequiredError('objectId','Required parameter requestParameters.objectId was null or undefined when calling getAlertAction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/alerts/{object_id}/action`.replace(`{${"object_id"}}`, encodeURIComponent(String(requestParameters.objectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultPerentieAlertActionFromJSON(jsonValue));
    }

    /**
     * Get alert action
     */
    async getAlertAction(objectId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultPerentieAlertAction> {
        const response = await this.getAlertActionRaw({ objectId: objectId }, initOverrides);
        return await response.value();
    }

    /**
     * Get alert details
     */
    async getAlertDetailsRaw(requestParameters: GetAlertDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultAlertDetails>> {
        if (requestParameters.alertId === null || requestParameters.alertId === undefined) {
            throw new runtime.RequiredError('alertId','Required parameter requestParameters.alertId was null or undefined when calling getAlertDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/alerts/{alert_id}`.replace(`{${"alert_id"}}`, encodeURIComponent(String(requestParameters.alertId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultAlertDetailsFromJSON(jsonValue));
    }

    /**
     * Get alert details
     */
    async getAlertDetails(alertId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultAlertDetails> {
        const response = await this.getAlertDetailsRaw({ alertId: alertId }, initOverrides);
        return await response.value();
    }

    /**
     * Get a Summary of the active events for an alert
     */
    async getAlertEventSummaryRaw(requestParameters: GetAlertEventSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultListActiveEventDetails>> {
        if (requestParameters.alertId === null || requestParameters.alertId === undefined) {
            throw new runtime.RequiredError('alertId','Required parameter requestParameters.alertId was null or undefined when calling getAlertEventSummary.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/alerts/{alert_id}/eventSummary`.replace(`{${"alert_id"}}`, encodeURIComponent(String(requestParameters.alertId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultListActiveEventDetailsFromJSON(jsonValue));
    }

    /**
     * Get a Summary of the active events for an alert
     */
    async getAlertEventSummary(alertId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultListActiveEventDetails> {
        const response = await this.getAlertEventSummaryRaw({ alertId: alertId }, initOverrides);
        return await response.value();
    }

    /**
     * Get the component state history of component for alert
     */
    async getComponentStateHistoryForAlertRaw(requestParameters: GetComponentStateHistoryForAlertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultListStateHistoryDetails>> {
        if (requestParameters.alertId === null || requestParameters.alertId === undefined) {
            throw new runtime.RequiredError('alertId','Required parameter requestParameters.alertId was null or undefined when calling getComponentStateHistoryForAlert.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/alerts/{alert_id}/stateHistory`.replace(`{${"alert_id"}}`, encodeURIComponent(String(requestParameters.alertId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultListStateHistoryDetailsFromJSON(jsonValue));
    }

    /**
     * Get the component state history of component for alert
     */
    async getComponentStateHistoryForAlert(alertId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultListStateHistoryDetails> {
        const response = await this.getComponentStateHistoryForAlertRaw({ alertId: alertId }, initOverrides);
        return await response.value();
    }

    /**
     * Get alerts that are active (active means enddate is null) or have status NOT_ACTIVE_OPEN (sticky alerts). A component may have more than one alert with status NOT_ACTIVE_OPEN.
     */
    async getOpenAlertsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultListAlertBasicDetails>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/alerts/openalerts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultListAlertBasicDetailsFromJSON(jsonValue));
    }

    /**
     * Get alerts that are active (active means enddate is null) or have status NOT_ACTIVE_OPEN (sticky alerts). A component may have more than one alert with status NOT_ACTIVE_OPEN.
     */
    async getOpenAlerts(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultListAlertBasicDetails> {
        const response = await this.getOpenAlertsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get recently ended alerts
     */
    async getRecentAlertsRaw(requestParameters: GetRecentAlertsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultListAlertBasicDetails>> {
        const queryParameters: any = {};

        if (requestParameters.num !== undefined) {
            queryParameters['num'] = requestParameters.num;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/alerts/recent`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultListAlertBasicDetailsFromJSON(jsonValue));
    }

    /**
     * Get recently ended alerts
     */
    async getRecentAlerts(num?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultListAlertBasicDetails> {
        const response = await this.getRecentAlertsRaw({ num: num }, initOverrides);
        return await response.value();
    }

    /**
     * Update alert client reference
     */
    async updateAlertClientReferenceRaw(requestParameters: UpdateAlertClientReferenceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultAlertDetails>> {
        if (requestParameters.alertId === null || requestParameters.alertId === undefined) {
            throw new runtime.RequiredError('alertId','Required parameter requestParameters.alertId was null or undefined when calling updateAlertClientReference.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/alerts/{alert_id}/reference`.replace(`{${"alert_id"}}`, encodeURIComponent(String(requestParameters.alertId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateAlertRequestToJSON(requestParameters.updateAlertRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultAlertDetailsFromJSON(jsonValue));
    }

    /**
     * Update alert client reference
     */
    async updateAlertClientReference(alertId: string, updateAlertRequest?: UpdateAlertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultAlertDetails> {
        const response = await this.updateAlertClientReferenceRaw({ alertId: alertId, updateAlertRequest: updateAlertRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Update alert client reference
     */
    async updateAlertCloseSummaryRaw(requestParameters: UpdateAlertCloseSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultAlertDetails>> {
        if (requestParameters.alertId === null || requestParameters.alertId === undefined) {
            throw new runtime.RequiredError('alertId','Required parameter requestParameters.alertId was null or undefined when calling updateAlertCloseSummary.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/alerts/{alert_id}/closeSummary`.replace(`{${"alert_id"}}`, encodeURIComponent(String(requestParameters.alertId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateAlertRequestToJSON(requestParameters.updateAlertRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultAlertDetailsFromJSON(jsonValue));
    }

    /**
     * Update alert client reference
     */
    async updateAlertCloseSummary(alertId: string, updateAlertRequest?: UpdateAlertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultAlertDetails> {
        const response = await this.updateAlertCloseSummaryRaw({ alertId: alertId, updateAlertRequest: updateAlertRequest }, initOverrides);
        return await response.value();
    }

}
