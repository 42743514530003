/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ComponentBasicDetails } from './ComponentBasicDetails';
import {
    ComponentBasicDetailsFromJSON,
    ComponentBasicDetailsFromJSONTyped,
    ComponentBasicDetailsToJSON,
} from './ComponentBasicDetails';
import type { NotifyChainDetails } from './NotifyChainDetails';
import {
    NotifyChainDetailsFromJSON,
    NotifyChainDetailsFromJSONTyped,
    NotifyChainDetailsToJSON,
} from './NotifyChainDetails';

/**
 * 
 * @export
 * @interface RosterDetails
 */
export interface RosterDetails {
    /**
     * 
     * @type {string}
     * @memberof RosterDetails
     */
    objectId?: string;
    /**
     * 
     * @type {string}
     * @memberof RosterDetails
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RosterDetails
     */
    componentIds?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: ComponentBasicDetails; }}
     * @memberof RosterDetails
     */
    componentMap?: { [key: string]: ComponentBasicDetails; };
    /**
     * 
     * @type {{ [key: string]: NotifyChainDetails; }}
     * @memberof RosterDetails
     */
    rosterDays?: { [key: string]: NotifyChainDetails; };
}

/**
 * Check if a given object implements the RosterDetails interface.
 */
export function instanceOfRosterDetails(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RosterDetailsFromJSON(json: any): RosterDetails {
    return RosterDetailsFromJSONTyped(json, false);
}

export function RosterDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RosterDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'objectId': !exists(json, 'objectId') ? undefined : json['objectId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'componentIds': !exists(json, 'componentIds') ? undefined : json['componentIds'],
        'componentMap': !exists(json, 'componentMap') ? undefined : (mapValues(json['componentMap'], ComponentBasicDetailsFromJSON)),
        'rosterDays': !exists(json, 'rosterDays') ? undefined : (mapValues(json['rosterDays'], NotifyChainDetailsFromJSON)),
    };
}

export function RosterDetailsToJSON(value?: RosterDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'objectId': value.objectId,
        'name': value.name,
        'componentIds': value.componentIds,
        'componentMap': value.componentMap === undefined ? undefined : (mapValues(value.componentMap, ComponentBasicDetailsToJSON)),
        'rosterDays': value.rosterDays === undefined ? undefined : (mapValues(value.rosterDays, NotifyChainDetailsToJSON)),
    };
}

