import styles from './ManageReportsPage.module.scss'
import { useEffect, useState } from "react";
import {ClientDetails, ComponentBasicDetails, ReportRequest, SupportReportResult} from "@perentie/common";
import {perDS} from "../../datasource/WebDS";
import Select, { MultiValue } from 'react-select'
import { Button, ContainerCard } from '@systemic-design-framework/react';
import { getUTCDateStringYYYYMMDD } from '../../utils/DateTimeUtils';

interface ReportFields {
  selectedComponents: ComponentBasicDetails[];
  fromDate: Date | null;
  toDate: Date | null;
  totalSupportHours: number;
  supportHoursByComponent: Record<string, number>;
}

type ReportLevel = 'client' | 'component';
const getTextMonthAndYearFromDate = (date: Date) => {
  return `${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`;
};

const RenderReport = (reportResult: SupportReportResult | null) => {
  if (!reportResult) {
    return <p>Select components and date range to generate a report.</p>;
  }

  return (
    <div className={styles.reportContainer}>
      <div className={styles.header}>
        <h2>Report Summary</h2>
      </div>
      <div className={styles.section}>
        <h3>Selected Components</h3>
        <ul>
          {reportResult.summaries?.map((summary) => (
            <li key={summary.componentDetails!.objectId}>
              {summary.componentDetails!.name} -{' '}
              {summary.totalSupportMinutes!.toFixed(2)} minutes ({(summary.totalSupportMinutes! / 60).toFixed(2)} hours)
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.section}>
        <h3>Date Range</h3>
        <p>From: {reportResult.criteria?.startDate?.toLocaleDateString()}</p>
        <p>To: {reportResult.criteria?.endDate?.toLocaleDateString()}</p>
      </div>
      <div className={styles.section}>
        <h3>Total Support Hours</h3>
        <p className={styles.totalHours}>{(reportResult.summaries?.reduce((acc, summary) => acc + summary.totalSupportMinutes!, 0) ?? 0) / 60} hours</p>
      </div>
    </div>
  );
};

function ManageReportsPage() {

  const [componentList, setComponentList] = useState<ComponentBasicDetails[]>([]);
  const [selectedComponents, setSelectedComponents] = useState<ComponentBasicDetails[]>([]);
  const [clientList, setClientList] = useState<ClientDetails[]>([]);
  const [selectedClient, setSelectedClient] = useState<ClientDetails | null>(null);
  const [reportLevel, setReportLevel] = useState<ReportLevel>('client');
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [generatedReport, setGeneratedReport] = useState<SupportReportResult | null>(null);

  useEffect(() => {
    perDS.adminGetAllComponents().then( res => {
      setComponentList(res);
    }).catch(reason => {
      console.log(' get components fail', reason);
    })
  }, []);

  useEffect(() => {
    perDS.getPerentieClients().then(res => {
      setClientList(res);
    }).catch(reason => {
      console.log(' get clients fail', reason);
    })
  }, []);

  useEffect(() => {
    if (!fromDate) {
      return;
    }
    if(!toDate || toDate < fromDate){
      setToDate(fromDate)
    }
  }, [fromDate]);

  useEffect(() => {
    if (!toDate) {
      return;
    }
    if(!fromDate || toDate < fromDate){
      setFromDate(toDate)
    }
  }, [toDate]);

  useEffect(() => {
    if(selectedClient){
      setSelectedComponents(selectedClient.components || []);
    }
  }, [selectedClient]);

  function getReportingPeriod(month: number, year: number) {
    const targetMonth = month;
    
    const lastDayOfMonth = new Date(year, targetMonth + 1, 0);
    
    const lastFridayOfMonth = new Date(lastDayOfMonth);
    while (lastFridayOfMonth.getDay() !== 5) {
      lastFridayOfMonth.setDate(lastFridayOfMonth.getDate() - 1);
    }
    
    const lastDayOfPreviousMonth = new Date(year, targetMonth, 0);
    
    const lastFridayOfPreviousMonth = new Date(lastDayOfPreviousMonth);
    while (lastFridayOfPreviousMonth.getDay() !== 5) {
      lastFridayOfPreviousMonth.setDate(lastFridayOfPreviousMonth.getDate() - 1);
      console.log(lastFridayOfPreviousMonth, lastFridayOfPreviousMonth.getTimezoneOffset());
    }
    const firstSaturdayAfterPreviousPeriod = new Date(lastFridayOfPreviousMonth)
    firstSaturdayAfterPreviousPeriod.setDate(lastFridayOfPreviousMonth.getDate() + 1);
    console.log(firstSaturdayAfterPreviousPeriod, getUTCDateStringYYYYMMDD(firstSaturdayAfterPreviousPeriod));
    
    return {
      fromDate: firstSaturdayAfterPreviousPeriod,
      toDate: lastFridayOfMonth
    };
  }

  const setDateRangeForMonth = (month: number, year: number) => {
    const period = getReportingPeriod(month, year);
    setFromDate(period.fromDate);
    setToDate(period.toDate);
  }

  const setDateRangePreviousFinancialYear = () => {
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth();
    const financialYearStartMonth = 6; // July
    const financialYearStartYear = currentMonth >= financialYearStartMonth ? currentYear - 1 : currentYear - 2;
    const financialYearEndMonth = 5; // June
    const financialYearEndYear = currentMonth >= financialYearStartMonth ? currentYear : currentYear - 1;
    setFromDate(getReportingPeriod(financialYearStartMonth, financialYearStartYear).fromDate);
    setToDate(getReportingPeriod(financialYearEndMonth, financialYearEndYear).toDate);
  }

  const setDateRangeThisFinancialYear = () => {
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth();
    const financialYearStartMonth = 6; // July
    const financialYearStartYear = currentMonth >= financialYearStartMonth ? currentYear : currentYear - 1;
    const financialYearEndMonth = 5; // June
    const financialYearEndYear = currentMonth >= financialYearStartMonth ? currentYear + 1 : currentYear;
    setFromDate(getReportingPeriod(financialYearStartMonth, financialYearStartYear).fromDate);
    setToDate(getReportingPeriod(financialYearEndMonth, financialYearEndYear).toDate);
  }

  const pastSixMonths = () => {
    const now = new Date();
    const currentMonth = now.getMonth();
    let pastSixMonths = [];
    for(let i = 0; i < 6; i++){
      pastSixMonths.push({month: currentMonth - i < 0 ? currentMonth - i + 12 : currentMonth - i, year: currentMonth - i < 0 ? now.getFullYear() - 1 : now.getFullYear()});
    }
    return pastSixMonths;
  }

  const pastSixMonthsList = pastSixMonths();

  const generateReport = () => {
    const req: ReportRequest = {
      componentIds: selectedComponents.map(component => component.objectId!),
      startDate: fromDate!,
      endDate: toDate!,
      includeZeroSupport: true
    }
    perDS.getSupportTimeReport(req).then(res => {
      console.log(' Report Generation success', res);
      setGeneratedReport(res);
    }).catch(reason => {
      console.log(' Report Generation failed', reason);
    });
  }

  return (
    <div className={styles['content']}>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
        <div style={{width: '100%', flex: 1}}>
          <ContainerCard HeaderComponent={<div>Generate a Report</div>}>
            <div className={styles.formField}>
              <label htmlFor='report-level'>Report Level</label>
              <div className={styles.buttonContainer}>
                <Button size='s' onClick={() => setReportLevel('client')} title='Client' variant={reportLevel === 'client' ? 'primary' : 'ghost'}/>
                <Button size='s' onClick={() => setReportLevel('component')} title='Component' variant={reportLevel === 'component' ? 'primary' : 'ghost'}/>
              </div>
            </div>
            <div className={styles.formField}>
              {reportLevel === 'client' && (
                <>
                <label htmlFor='client-select'>Select Client</label>
                  <Select
                  id="client-select"
                  options={clientList}
                  isClearable
                  getOptionLabel={(option: ClientDetails) => option.name!}
                  getOptionValue={(option: ClientDetails) => option.objectId!}
                  placeholder="Select Client"
                  onChange={(client: ClientDetails | null) => setSelectedClient(client)}
                  value={selectedClient}
                  />
                </>
              )}

              
              {reportLevel === 'component' && (
                <>
              <label htmlFor='component-select'>Select Components</label>
              <Select
                id="component-select"
                isMulti
                options={componentList}
                getOptionLabel={(option: ComponentBasicDetails) => option.name!}
                getOptionValue={(option: ComponentBasicDetails) => option.objectId!}
                placeholder="Select Components"
                onChange={(selected: MultiValue<ComponentBasicDetails>) => setSelectedComponents(selected as ComponentBasicDetails[])}
                value={selectedComponents}
                closeMenuOnSelect={false}
                />
                </>)}
            </div>
            <div className={styles.formField}>
              <label htmlFor='from-date'>From Date</label>
              <input type='date' id='from-date' value={fromDate ? getUTCDateStringYYYYMMDD(fromDate) : undefined} onChange={(e) => setFromDate(new Date(e.target.value))}/>
            </div>
            <div className={styles.formField}>
              <label htmlFor='to-date'>To Date</label>
              <input type='date' id='to-date' value={toDate ? getUTCDateStringYYYYMMDD(toDate) : undefined} onChange={(e) => setToDate(new Date(e.target.value))}/>
            </div>
            {/* <Button size='s' onClick={setDateRangePreviousReportingPeriod} title='Previous Reporting Period'/> */}
            Quick Select Reporting Periods:
            <div className={styles.buttonContainer}>
              {pastSixMonthsList.map((item, index) => (
                <Button key={index} size='s' onClick={() => setDateRangeForMonth(item.month, item.year)} title={getTextMonthAndYearFromDate(new Date(item.year, item.month))}/> 
              ))}
              <div className={styles.spacer}></div>
              <Button size='s' onClick={setDateRangeThisFinancialYear} title='This Financial Year'/>
              <Button size='s' onClick={setDateRangePreviousFinancialYear} title='Previous Financial Year'/>
            </div>

            <div className={styles.actions}>
              <Button size='sm' variant='secondary-dark' title='Clear' onClick={() => {
                setGeneratedReport(null);
              }}/>
              <Button size='sm' title='Generate Report' onClick={generateReport} disabled={!selectedComponents.length || !fromDate || !toDate}/>
            </div>
          </ContainerCard>
        </div>
        <div style={{width: '100%', flex: 1}}>
          <ContainerCard HeaderComponent={<div>Report</div>}>
            <RenderReport {...generatedReport}/>
          </ContainerCard>
        </div>
      </div>
    </div>
  )
}

export default ManageReportsPage;
