
import styles from './ManageClientsPage.module.scss'
import {useEffect, useState} from "react";
import {ClientDetails} from "@perentie/common";
import {perDS} from "../../datasource/WebDS";
import {Button, Cell, ContainerCard, Row, Table} from "@systemic-design-framework/react";
import { useNavigate } from 'react-router-dom';
function ManageClientsPage(){

  const [clients, setClients] = useState<ClientDetails[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    perDS.getPerentieClients().then( res => {
      setClients(res);
    }).catch( reason => {
      console.log(' getPerentieClients fail ', reason);
    })
  }, []);

  const renderItem = (item: ClientDetails, index: number) => {
    return (
      <Row key={index} striped={index % 2 == 1}>
        <Cell>{item.systemClient === true ? '[SYSTEM] ' : ''}{item.name}</Cell>
        <Cell>{item.description}</Cell>
        <Cell>{item.components != null ? item.components.length : '0'}</Cell>
        <Cell>{item.systemClient === true ? <></> : 
          <Button
            variant="ghost"
            size="s"
            title="View/Edit Client"
            onClick={() => {
                navigate(`/clients/details/${item.objectId}`);
            }}/>}</Cell>
      </Row>
    )
  }

  const TableHeader = () => {
    return (
      <Row>
        <Cell>Name</Cell>
        <Cell>Description</Cell>
        <Cell>Components</Cell>
        <Cell>View/Edit</Cell>
      </Row>
    );
  };

  return (
    <div className={styles['content']}>

      <ContainerCard
        HeaderComponent={
          <div style={{display:'flex', justifyContent:'space-between', flex:'1'}}>
            Perentie Clients <Button title="Add Client" onClick={() => {navigate(`/clients/add`);}} size="s"/>
          </div>
        }
      >
        <Table data={clients} renderItem={renderItem} HeaderComponent={<TableHeader />} border />
      </ContainerCard>
    </div>
  )
}

export default ManageClientsPage;
