import {AlertBasicDetails, AlertDetails, AlertDetailsStatusEnum, TimeUtil} from "@perentie/common";
import styles from "./BasicAlertCard.module.scss";
import {Button} from "@systemic-design-framework/react";
import StateText from "../StateText/StateText";
import {IoReaderOutline, IoTimeOutline} from "react-icons/io5";

export interface BasicAlertCardProps {
  details: AlertBasicDetails;
  onClick?: (item: AlertDetails) => void;
  actionClick?: (item: AlertDetails, action: 'close') => void;
  showState?: boolean;
}
function BasicAlertCard(props:BasicAlertCardProps) {
  const {showState = true, details} = props;
  const timeUtil = new TimeUtil();
  const cardClick = () => {
    if (!!props.onClick){
      props.onClick(props.details);
    }
  }
  const closeClick = () => {
    if (!!props.actionClick){
      props.actionClick(props.details, 'close');
    }
  }

  const divStopper = (evt: any) =>{
    evt.stopPropagation();
  }

  const getBorder = (): string => {
    if (details.status == AlertDetailsStatusEnum.ActiveOpen) {
      return 'border-'+details.componentState?.toLowerCase();
    }
    return 'border-resolved';
  }

  {`${styles["alert-info-val-clickable"]}`}

  return (
    <div className={`${styles['alertCard']} ${styles[getBorder()]}`} onClick={cardClick}>
      <div className={styles['title']}>
        <h3 key={'b'}>{details.componentName}</h3>
        <div style={{display: 'flex', flexDirection: 'row', gap: 6}}>
          {!!details && details.numComments && details.numComments > 0 ? <IoReaderOutline/> : []}
          {!!details && details.supportMinutes && details.supportMinutes > 0 ? <IoTimeOutline/> : []}
          {details.status == AlertDetailsStatusEnum.ActiveOpen ? [<StateText>{details.componentState}</StateText>] : ''}
        </div>
      </div>
      <table className={styles['detailsTable']}>
        <tbody>
        {/*<tr>*/}
        {/*  <td>Component State:</td>*/}
        {/*  <td style={{fontWeight: "bold"}}>{details.status == AlertDetailsStatusEnum.ActiveOpen ? [<StateText>{details.componentState}</StateText>] : ''}</td>*/}
        {/*</tr>*/}
        <tr>
          <td>Alert Status:</td>
          <td>{details.statusDisplay}</td>
        </tr>
        <tr>
          <td>Perentie Ref:</td>
          <td>{details.reference}</td>
        </tr>
        <tr>
          <td>Client Ref:</td>
          <td>{details.clientReference}</td>
        </tr>
        <tr>
          <td>Start / End:</td>
          <td><span>{details?.created?.toLocaleString([], {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
            hour: '2-digit',
            minute: '2-digit'
          })} / {details?.ended == null ? '~' : details.ended.toLocaleString([], {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
            hour: '2-digit',
            minute: '2-digit'
          })}</span></td>
        </tr>
        <tr>
          <td>Handled By:</td>
          <td>{details.handlingUser != null ? details.handlingUser.username : 'Not Handled'}</td>
        </tr>
        <tr>
          <td>Support Minutes:</td>
          <td>{timeUtil.formatMinutes(details?.supportMinutes!, "HOURS_AND_MINUTES")}</td>
        </tr>
        </tbody>
      </table>
      {props.actionClick != null && (
        <div style={{display: "inline-block"}} onClick={divStopper}>
          <Button size={'s'} title={'Close Alert'} onClick={closeClick} />
        </div>
      )}
    </div>
  )
}

export default BasicAlertCard;
