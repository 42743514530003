/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  RESTResultListScheduleDetails,
  RESTResultMapStringString,
  RESTResultScheduleDetails,
  UpdateScheduleRequest,
} from '../models/index';
import {
    RESTResultListScheduleDetailsFromJSON,
    RESTResultListScheduleDetailsToJSON,
    RESTResultMapStringStringFromJSON,
    RESTResultMapStringStringToJSON,
    RESTResultScheduleDetailsFromJSON,
    RESTResultScheduleDetailsToJSON,
    UpdateScheduleRequestFromJSON,
    UpdateScheduleRequestToJSON,
} from '../models/index';

export interface CreateScheduleRequest {
    updateScheduleRequest?: UpdateScheduleRequest;
}

export interface GetScheduleRequest {
    id: string;
}

export interface UpdateScheduleOperationRequest {
    id: string;
    updateScheduleRequest?: UpdateScheduleRequest;
}

/**
 * PerentieScheduleWSApi - interface
 * 
 * @export
 * @interface PerentieScheduleWSApiInterface
 */
export interface PerentieScheduleWSApiInterface {
    /**
     * ADMIN ONLY -Create a new Perentie Schedule
     * @param {UpdateScheduleRequest} [updateScheduleRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieScheduleWSApiInterface
     */
    createScheduleRaw(requestParameters: CreateScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultScheduleDetails>>;

    /**
     * ADMIN ONLY -Create a new Perentie Schedule
     */
    createSchedule(updateScheduleRequest?: UpdateScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultScheduleDetails>;

    /**
     * ADMIN ONLY - Get all Perentie Schedules
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieScheduleWSApiInterface
     */
    getAllSchedulesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultListScheduleDetails>>;

    /**
     * ADMIN ONLY - Get all Perentie Schedules
     */
    getAllSchedules(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultListScheduleDetails>;

    /**
     * ADMIN ONLY - Get a Perentie Schedule by ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieScheduleWSApiInterface
     */
    getScheduleRaw(requestParameters: GetScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultScheduleDetails>>;

    /**
     * ADMIN ONLY - Get a Perentie Schedule by ID
     */
    getSchedule(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultScheduleDetails>;

    /**
     * Get Timezone Options
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieScheduleWSApiInterface
     */
    getTimezoneOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultMapStringString>>;

    /**
     * Get Timezone Options
     */
    getTimezoneOptions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultMapStringString>;

    /**
     * ADMIN ONLY - Update a Perentie Schedule
     * @param {string} id 
     * @param {UpdateScheduleRequest} [updateScheduleRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieScheduleWSApiInterface
     */
    updateScheduleRaw(requestParameters: UpdateScheduleOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultScheduleDetails>>;

    /**
     * ADMIN ONLY - Update a Perentie Schedule
     */
    updateSchedule(id: string, updateScheduleRequest?: UpdateScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultScheduleDetails>;

}

/**
 * 
 */
export class PerentieScheduleWSApi extends runtime.BaseAPI implements PerentieScheduleWSApiInterface {

    /**
     * ADMIN ONLY -Create a new Perentie Schedule
     */
    async createScheduleRaw(requestParameters: CreateScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultScheduleDetails>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/schedule/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateScheduleRequestToJSON(requestParameters.updateScheduleRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultScheduleDetailsFromJSON(jsonValue));
    }

    /**
     * ADMIN ONLY -Create a new Perentie Schedule
     */
    async createSchedule(updateScheduleRequest?: UpdateScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultScheduleDetails> {
        const response = await this.createScheduleRaw({ updateScheduleRequest: updateScheduleRequest }, initOverrides);
        return await response.value();
    }

    /**
     * ADMIN ONLY - Get all Perentie Schedules
     */
    async getAllSchedulesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultListScheduleDetails>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/schedule/schedules`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultListScheduleDetailsFromJSON(jsonValue));
    }

    /**
     * ADMIN ONLY - Get all Perentie Schedules
     */
    async getAllSchedules(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultListScheduleDetails> {
        const response = await this.getAllSchedulesRaw(initOverrides);
        return await response.value();
    }

    /**
     * ADMIN ONLY - Get a Perentie Schedule by ID
     */
    async getScheduleRaw(requestParameters: GetScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultScheduleDetails>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getSchedule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/schedule/schedule/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultScheduleDetailsFromJSON(jsonValue));
    }

    /**
     * ADMIN ONLY - Get a Perentie Schedule by ID
     */
    async getSchedule(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultScheduleDetails> {
        const response = await this.getScheduleRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * Get Timezone Options
     */
    async getTimezoneOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultMapStringString>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/schedule/zones`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultMapStringStringFromJSON(jsonValue));
    }

    /**
     * Get Timezone Options
     */
    async getTimezoneOptions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultMapStringString> {
        const response = await this.getTimezoneOptionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * ADMIN ONLY - Update a Perentie Schedule
     */
    async updateScheduleRaw(requestParameters: UpdateScheduleOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultScheduleDetails>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateSchedule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/schedule/update/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateScheduleRequestToJSON(requestParameters.updateScheduleRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultScheduleDetailsFromJSON(jsonValue));
    }

    /**
     * ADMIN ONLY - Update a Perentie Schedule
     */
    async updateSchedule(id: string, updateScheduleRequest?: UpdateScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultScheduleDetails> {
        const response = await this.updateScheduleRaw({ id: id, updateScheduleRequest: updateScheduleRequest }, initOverrides);
        return await response.value();
    }

}
