/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  RESTResultListRosterDetails,
  RESTResultRosterDetails,
  RosterDetails,
} from '../models/index';
import {
    RESTResultListRosterDetailsFromJSON,
    RESTResultListRosterDetailsToJSON,
    RESTResultRosterDetailsFromJSON,
    RESTResultRosterDetailsToJSON,
    RosterDetailsFromJSON,
    RosterDetailsToJSON,
} from '../models/index';

export interface DeleteRosterRequest {
    rosterId: string;
}

export interface GetRosterDetailsRequest {
    rosterId: string;
}

export interface UpdateRosterDetailsRequest {
    rosterDetails?: RosterDetails;
}

/**
 * PerentieRosterWSApi - interface
 * 
 * @export
 * @interface PerentieRosterWSApiInterface
 */
export interface PerentieRosterWSApiInterface {
    /**
     * Delete Roster
     * @param {string} rosterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieRosterWSApiInterface
     */
    deleteRosterRaw(requestParameters: DeleteRosterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultRosterDetails>>;

    /**
     * Delete Roster
     */
    deleteRoster(rosterId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultRosterDetails>;

    /**
     * Get All Rosters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieRosterWSApiInterface
     */
    getAllRostersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultListRosterDetails>>;

    /**
     * Get All Rosters
     */
    getAllRosters(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultListRosterDetails>;

    /**
     * Get Roster
     * @param {string} rosterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieRosterWSApiInterface
     */
    getRosterDetailsRaw(requestParameters: GetRosterDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultRosterDetails>>;

    /**
     * Get Roster
     */
    getRosterDetails(rosterId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultRosterDetails>;

    /**
     * Create or Update Roster
     * @param {RosterDetails} [rosterDetails] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieRosterWSApiInterface
     */
    updateRosterDetailsRaw(requestParameters: UpdateRosterDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultRosterDetails>>;

    /**
     * Create or Update Roster
     */
    updateRosterDetails(rosterDetails?: RosterDetails, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultRosterDetails>;

}

/**
 * 
 */
export class PerentieRosterWSApi extends runtime.BaseAPI implements PerentieRosterWSApiInterface {

    /**
     * Delete Roster
     */
    async deleteRosterRaw(requestParameters: DeleteRosterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultRosterDetails>> {
        if (requestParameters.rosterId === null || requestParameters.rosterId === undefined) {
            throw new runtime.RequiredError('rosterId','Required parameter requestParameters.rosterId was null or undefined when calling deleteRoster.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/roster/{roster_id}`.replace(`{${"roster_id"}}`, encodeURIComponent(String(requestParameters.rosterId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultRosterDetailsFromJSON(jsonValue));
    }

    /**
     * Delete Roster
     */
    async deleteRoster(rosterId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultRosterDetails> {
        const response = await this.deleteRosterRaw({ rosterId: rosterId }, initOverrides);
        return await response.value();
    }

    /**
     * Get All Rosters
     */
    async getAllRostersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultListRosterDetails>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/roster/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultListRosterDetailsFromJSON(jsonValue));
    }

    /**
     * Get All Rosters
     */
    async getAllRosters(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultListRosterDetails> {
        const response = await this.getAllRostersRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get Roster
     */
    async getRosterDetailsRaw(requestParameters: GetRosterDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultRosterDetails>> {
        if (requestParameters.rosterId === null || requestParameters.rosterId === undefined) {
            throw new runtime.RequiredError('rosterId','Required parameter requestParameters.rosterId was null or undefined when calling getRosterDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/roster/{roster_id}`.replace(`{${"roster_id"}}`, encodeURIComponent(String(requestParameters.rosterId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultRosterDetailsFromJSON(jsonValue));
    }

    /**
     * Get Roster
     */
    async getRosterDetails(rosterId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultRosterDetails> {
        const response = await this.getRosterDetailsRaw({ rosterId: rosterId }, initOverrides);
        return await response.value();
    }

    /**
     * Create or Update Roster
     */
    async updateRosterDetailsRaw(requestParameters: UpdateRosterDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultRosterDetails>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/roster/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RosterDetailsToJSON(requestParameters.rosterDetails),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultRosterDetailsFromJSON(jsonValue));
    }

    /**
     * Create or Update Roster
     */
    async updateRosterDetails(rosterDetails?: RosterDetails, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultRosterDetails> {
        const response = await this.updateRosterDetailsRaw({ rosterDetails: rosterDetails }, initOverrides);
        return await response.value();
    }

}
