import React, { useEffect, useState } from 'react';
import { ComponentBasicDetails } from "@perentie/common";
import { Button, Cell, Row, Table } from "@systemic-design-framework/react";
import StateText from "../../components/StateText/StateText";
import styles from './ComponentsListPage.module.scss';

interface ComponentsListPageProps {
    components: ComponentBasicDetails[];
    rawConfig: string;
    uploadConfig: (data: File) => void;
}

const ComponentsListPage = ({ components, uploadConfig, rawConfig}: ComponentsListPageProps) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editedConfig, setEditedConfig] = useState(rawConfig);

    useEffect(() => {
        if (!isEditing) {
            setEditedConfig(rawConfig);
        }
    }, [rawConfig, isEditing]);

    const uploadChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            setSelectedFile(file);
        }
    };

    const uploadClick = (): void => {
        if (selectedFile) {
            uploadConfig(selectedFile);
            setSelectedFile(null);
        }
    };

    const handleConfigSubmit = () => {
        const configFile = new File([editedConfig], 'config.yaml', { type: 'text/yaml' });
        uploadConfig(configFile);
        setIsEditing(false);
    };

    const TableHeader = () => (
        <Row>
            <Cell>State</Cell>
            <Cell>Name</Cell>
            <Cell>Identifier</Cell>
        </Row>
    );

    const renderItem = (item: ComponentBasicDetails, index: number) => (
        <Row key={index} striped={index % 2 === 1}>
            <Cell>
                <StateText>{item.state}</StateText>
            </Cell>
            <Cell>{item.name}</Cell>
            <Cell>{item.systemIdentifier}</Cell>
        </Row>
    );

    const sortComponents = (a: ComponentBasicDetails, b: ComponentBasicDetails) => {
        const priorityOrder = {
            "CRITICAL": 0,
            "SEVERE": 1,
            "WARNING": 2,
            "INFO": 3,
            "OK": 4,
            "UNKNOWN": 5
        };

        if (a.state === b.state) {
            return a.name != null && b.name != null ? a.name.localeCompare(b.name) : 1;
        }
        return a.state != null && b.state != null ? priorityOrder[a.state] - priorityOrder[b.state] : 1;
    };

    return (
        <div className={styles.container}>
            <Table 
                data={components.sort(sortComponents)} 
                renderItem={renderItem} 
                HeaderComponent={<TableHeader />} 
                border
            />
            
            <div className={styles.divider} />
            
            <div className={styles.configSection}>
                <div className={styles.header}>
                    <div className={styles.uploadSection}>
                        <h3>Component Config | </h3>
                        <label htmlFor="configFile">Upload Config:</label>
                        <input 
                            type="file" 
                            id="configFile" 
                            accept=".yml, .yaml" 
                            max={1}
                            onChange={uploadChange}
                        />
                        {selectedFile && (
                            <Button 
                                title="Upload"  
                                size="s"  
                                onClick={uploadClick}
                            />
                        )}
                    </div>
                    <div className={styles.actions}>
                        {!isEditing ? (
                            <>
                                <button
                                    className={styles.editButton}
                                    onClick={() => setIsEditing(true)}
                                >
                                    Edit
                                </button>
                                <a 
                                    role="button" 
                                    className={styles.downloadButton}
                                    href="/perentie/admin/componentConfig" 
                                    target="_blank"
                                >
                                    Download
                                </a>
                            </>
                        ) : (
                            <>
                                <button
                                    className={styles.saveButton}
                                    onClick={handleConfigSubmit}
                                >
                                    Save
                                </button>
                                <button
                                    className={styles.cancelButton}
                                    onClick={() => {
                                        setIsEditing(false);
                                        setEditedConfig(rawConfig);
                                    }}
                                >
                                    Cancel
                                </button>
                            </>
                        )}
                    </div>
                </div>
                
                {isEditing ? (
                    <div className={styles.editorContainer}>
                        <textarea
                            value={editedConfig}
                            onChange={(e) => setEditedConfig(e.target.value)}
                            className={styles.editor}
                            spellCheck={false}
                            />
                    </div>
                ) : (
                    <pre className={styles.preview}>
                        {rawConfig}
                    </pre>
                )}
            </div>
        </div>
    );
};

export default ComponentsListPage;