import React from "react";
import Modal from 'react-modal'
const ReactModal = Modal as unknown as React.FC<any>;
import styles from "./Modal.module.scss"
import { IoClose } from "react-icons/io5";
interface ModalProps {
  visible: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  title: string;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  width?: number;
  height?: number;
}

const PerentieModal: React.FC<ModalProps> = ({ visible, onOpen, onClose, width = 600, height = 250, title, children, footer }) => {

  return (
    <ReactModal
        isOpen={visible}
        onAfterOpen={onOpen}
        onRequestClose={onClose}
        className={`${styles["modal"]}`}
        ariaHideApp={false}
        style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
            content: {
                width: width,
                height: height,
            }
        }}>
        <div className={styles["modal-body"]}>
            <div className={styles["header"]}>
                <h3>{title}</h3>
                <button onClick={onClose}>
                    <IoClose size={26}/>
                </button>
            </div>
            <div className={styles["content"]}>
                {children}
            </div>
            <div className={styles["footer"]}>
                {footer}
            </div>
        </div>
  </ReactModal>
  );
};

export default PerentieModal;
