/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  RESTResultSupportReportResult,
  ReportRequest,
} from '../models/index';
import {
    RESTResultSupportReportResultFromJSON,
    RESTResultSupportReportResultToJSON,
    ReportRequestFromJSON,
    ReportRequestToJSON,
} from '../models/index';

export interface GenerateSupportTimeReportRequest {
    reportRequest?: ReportRequest;
}

/**
 * PerentieReportWSApi - interface
 * 
 * @export
 * @interface PerentieReportWSApiInterface
 */
export interface PerentieReportWSApiInterface {
    /**
     * Generate support report
     * @param {ReportRequest} [reportRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieReportWSApiInterface
     */
    generateSupportTimeReportRaw(requestParameters: GenerateSupportTimeReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultSupportReportResult>>;

    /**
     * Generate support report
     */
    generateSupportTimeReport(reportRequest?: ReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultSupportReportResult>;

}

/**
 * 
 */
export class PerentieReportWSApi extends runtime.BaseAPI implements PerentieReportWSApiInterface {

    /**
     * Generate support report
     */
    async generateSupportTimeReportRaw(requestParameters: GenerateSupportTimeReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultSupportReportResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/report/support`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReportRequestToJSON(requestParameters.reportRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultSupportReportResultFromJSON(jsonValue));
    }

    /**
     * Generate support report
     */
    async generateSupportTimeReport(reportRequest?: ReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultSupportReportResult> {
        const response = await this.generateSupportTimeReportRaw({ reportRequest: reportRequest }, initOverrides);
        return await response.value();
    }

}
