import { RosterDetails } from '@perentie/common'
import styles from './ManageRostersPage.module.scss'
import { useEffect, useState } from 'react'
import { perDS } from '../../datasource/WebDS'
import { Button, Cell, ContainerCard, Row, Table } from '@systemic-design-framework/react'
import { IoPencil, IoTrash } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'

const ManageRostersPage = () => {
  const [rosters, setRosters] = useState<RosterDetails[]>([])
  const navigate = useNavigate()

  useEffect(() => {
    perDS.getAllRosters().then(res => {
      setRosters(res)
    })
  }, [])

  const handleDeleteRoster = (roster: RosterDetails) => {
    if (roster?.objectId) {
      perDS.deleteRoster(roster.objectId).then(res => {
        console.log('Roster deleted')
        setRosters(rosters.filter(r => r.objectId !== res.objectId))
      })
    } else {
      console.error('No roster ID')
    }
  }

  const handleSelectRoster = (rosterId: string | undefined) => {
    navigate(`/rosters/details/${rosterId}`)
  }

  const numberOfRosteredDays = (roster: RosterDetails) => {
    let res = 0;
    Object.keys(roster.rosterDays!).forEach(day => {
      const links = roster.rosterDays![day].links
      if (links && links.length > 0) {
        res++
      }
    })
    return res
  }

  const renderItem = (item: RosterDetails, index: number) => {
    return (
      <Row key={index} striped={index % 2 == 1}>
        <Cell>{item.name}</Cell>
        <Cell>{item.componentIds?.length ?? 0}</Cell>
        <Cell>{numberOfRosteredDays(item)}</Cell>
        <Cell className={styles['actions']}>
          <Button size={'s'} variant={'ghost'} Icon={<IoPencil />} title='Edit' onClick={() => handleSelectRoster(item.objectId)} />
          <Button size={'s'} variant={'ghost'} Icon={<IoTrash />} title='Delete' onClick={() => handleDeleteRoster(item)} />
        </Cell>
      </Row>
    )
  }

  const TableHeader = () => {
    return (
      <Row>
        <Cell>Name</Cell>
        <Cell>Components</Cell>
        <Cell>Rostered Days</Cell>
        <Cell>Actions</Cell>
      </Row>
    );
  }

  return (
    <div className={styles["content"]}>
      <ContainerCard HeaderComponent={<div style={{display:'flex', justifyContent:'space-between', flex:'1'}}>
        Rosters
        <Button size={'s'} title='Add Roster' onClick={() => handleSelectRoster('new')} />
        </div>}>
        <Table data={rosters} renderItem={renderItem} HeaderComponent={<TableHeader />} border />
      </ContainerCard>
    </div>
  )
}

export default ManageRostersPage