import styles from "./ManageSchedules.module.scss"
import {useEffect, useState} from "react";
import {perDS} from "../../datasource/WebDS";
import {ScheduleDetails} from "@perentie/common";
import { Button, Cell, ContainerCard, Row, Table } from "@systemic-design-framework/react";
import {IoBuildOutline} from "react-icons/io5";
import { useNavigate } from "react-router-dom";
function ManageSchedulesPage(){
  const [schedList, setSchedList] = useState<ScheduleDetails[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    perDS.getSchedules().then(res => {
      setSchedList(res);
    }).catch(reason => {
      console.log(' getSchedules catch', reason);
    })
  }, []);

  const renderItem = (item: ScheduleDetails, index: number) => {
    return <Row key={index} striped={index % 2 == 1}>
      <Cell>{item._default ? '[SYSTEM] ' : ''}{item.name}</Cell>
      <Cell>{item.key}</Cell>
      <Cell>{item.description}</Cell>
      <Cell>{item.timeZone ?? '-'}</Cell>
      <Cell><Button size="s" variant="ghost" title="Edit" Icon={<IoBuildOutline/>} onClick={() => {navigate(`/schedules/${item.objectId}`)}}/></Cell>
    </Row>
  }

  const Header = () => {
    return <Row>
        <Cell>Name</Cell>
        <Cell>Key</Cell>
        <Cell>Description</Cell>
        <Cell>Timezone</Cell>
        <Cell>Actions</Cell>
    </Row>
  }

  return (
    <div className={styles['content']}>
      <ContainerCard HeaderComponent={<div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
        Schedules
        <Button size="s" title="Create Schedule" onClick={() => {navigate('/schedules/new')}}/>
      </div>}>
        <Table border data={schedList} renderItem={renderItem} HeaderComponent={<Header />}/>
      </ContainerCard>
    </div>
  )
}

export default ManageSchedulesPage;
