/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportRequest
 */
export interface ReportRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportRequest
     */
    componentIds?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof ReportRequest
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReportRequest
     */
    endDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ReportRequest
     */
    includeZeroSupport?: boolean;
}

/**
 * Check if a given object implements the ReportRequest interface.
 */
export function instanceOfReportRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ReportRequestFromJSON(json: any): ReportRequest {
    return ReportRequestFromJSONTyped(json, false);
}

export function ReportRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'componentIds': !exists(json, 'componentIds') ? undefined : json['componentIds'],
        'startDate': !exists(json, 'startDate') ? undefined : (new Date(json['startDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (new Date(json['endDate'])),
        'includeZeroSupport': !exists(json, 'includeZeroSupport') ? undefined : json['includeZeroSupport'],
    };
}

export function ReportRequestToJSON(value?: ReportRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'componentIds': value.componentIds,
        'startDate': value.startDate === undefined ? undefined : (value.startDate.toISOString()),
        'endDate': value.endDate === undefined ? undefined : (value.endDate.toISOString()),
        'includeZeroSupport': value.includeZeroSupport,
    };
}

