import {StateHistoryDetails} from "@perentie/common";
import StateText from "../../components/StateText/StateText";

export interface StateHistoryComponentProps {
  historyItem: StateHistoryDetails;
}

function StateHistoryComponent(props: StateHistoryComponentProps){
  const {historyItem} = props;
  return (
    <div style={{border: '1px solid #6AAD00', borderRadius: 6, padding: 8, fontSize: 14}}>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
        <p style={{margin: '0 0 4px 8px'}}><StateText>{historyItem.lastState}</StateText> --&gt; <StateText>{historyItem.nextState}</StateText></p>
        <p style={{fontStyle: 'italic', color: '#808080'}}>{historyItem.timeStamp?.toLocaleDateString()}, {historyItem.timeStamp?.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})}</p>
      </div>
      <p style={{margin: '0 0 4px 8px'}}>Rule Triggered: {historyItem.ruleTrigger == null ? '-' : historyItem.ruleTrigger}</p>
      <p style={{margin: '0 0 4px 8px'}}>Event Count: <b>{historyItem.events?.length}</b></p>
    </div>
  )
}

export default StateHistoryComponent;
