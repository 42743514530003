import styles from './ManageRosterPage.module.scss';
import { useEffect, useState, useCallback } from 'react';
import { perDS } from '../../../datasource/WebDS';

import { Button, Cell, ContainerCard, Row, Table } from '@systemic-design-framework/react';
import NotifyChainComponent from '../../ManageComponentsPage/NotifyChainComponent';
import { useNavigate, useParams } from 'react-router-dom';
import { ComponentDetails, NotifyChainDetails, RosterDetails, UserDetails } from '@perentie/common';
import SimpleNotifyChain from '../SimpleNotifyChain';
import { Text } from '../../../components/Input';
import Select from 'react-select';

const rosterDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const createBlankRoster = () => ({
    componentIds: [],
    name: '',
    componentMap: {},
    objectId: 'new',
    rosterDays: rosterDays.reduce((acc, day) => ({ ...acc, [day]: { componentId: undefined, links: [] } }), {}),
});

function ManageRosterPage() {
    const { rosterId } = useParams();
    const [roster, setRoster] = useState<RosterDetails | null>(null);
    const [originalRoster, setOriginalRoster] = useState<RosterDetails | null>(null);
    const [availableComponents, setAvailableComponents] = useState<ComponentDetails[]>([]);
    const [users, setUsers] = useState<UserDetails[]>([]);
    const [chain, setChain] = useState<NotifyChainDetails | null | undefined>(null);
    const [selectedDay, setSelectedDay] = useState<string | null>(null);
    const [isDirty, setIsDirty] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();

    const onCancel = useCallback(() => {
        navigate('/rosters');
    }, [navigate]);

    useEffect(() => {
        if (rosterId && rosterId !== 'new') {
            perDS.getRosterDetails(rosterId).then((res) => {
                setRoster(res);
                setOriginalRoster(res);
            });
        } else {
            const newRoster = createBlankRoster();
            setRoster(newRoster);
            setOriginalRoster(newRoster);
        }

        perDS.getPerentieUsers().then(setUsers);
        perDS.adminGetAllComponents().then((res) => {
            setAvailableComponents(res.filter((c) => !c.rosterId || c.rosterId === roster?.objectId));
            setIsLoading(false);
        });
    }, [rosterId, roster?.objectId]);

    useEffect(() => {
        setIsDirty(JSON.stringify(roster) !== JSON.stringify(originalRoster));
    }, [roster, originalRoster]);

    const handleSaveRoster = () => {
        if (!roster) return;
        perDS.updateRosterDetails(roster).then(() => navigate('/rosters'));
    };

    const handleEditDay = (day: string) => {
        setSelectedDay(day);
        setChain(structuredClone(roster?.rosterDays![day]));
    };

    const handleSaveNotifyChain = () => {
        if (!selectedDay || !roster || !chain) return;
        setRoster((prev) => ({
                    ...prev,
                    rosterDays: {
                        ...prev?.rosterDays,
                        [selectedDay]: chain || { componentId: undefined, links: [] },
                    },
                }));
        setChain(null);
        setSelectedDay(null);
    };

    const availableComponentOptions = availableComponents.map((c) => ({ label: c.name!, value: c.objectId! }));

    return (
        <div className={styles['content']}>
            <ContainerCard
                HeaderComponent={<div style={{ display: 'flex', justifyContent: 'space-between', flex: '1' }}>Roster Details</div>}
            >
                {roster && (
                    <>
                        <Text
                            id="roster-name"
                            label="Name"
                            valueChange={(value) =>
                                setRoster((prev) => ({
                                    ...prev,
                                    name: value,
                                }))
                            }
                            value={roster.name}
                        />
                        <div>
                            <label htmlFor="roster-components">Components</label>
                            <Select
                                id="roster-components"
                                isMulti
                                options={availableComponentOptions}
                                closeMenuOnSelect={false}
                                value={availableComponentOptions.filter((option) =>
                                    roster.componentIds?.includes(option.value)
                                )}
                                onChange={(selected: any) =>
                                    setRoster((prev) => ({
                                        ...prev,
                                        componentIds: selected.map((option: any) => option.value),
                                    }))
                                }
                                isLoading={isLoading}
                            />
                        </div>
                        <ContainerCard HeaderComponent={<div>Notify Chains</div>}>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                                {rosterDays.map((day) => (
                                    <div key={day} style={{ flex: 1, border: '1px solid #ccc', borderRadius: 4, padding: 8, margin: 8 }}>
                                        <div style={{marginBottom: 8, padding: 4, borderRadius: 4, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: 8}} className={selectedDay === day ? styles['selectedDay'] : ''}>{day}<Button title="Edit" size="s" onClick={() => handleEditDay(day)} /></div>
                                        
                                        <SimpleNotifyChain chain={roster.rosterDays![day]} />
                                    </div>
                                ))}
                            </div>
                        </ContainerCard>
                    </>
                )}
                {selectedDay && chain && (
                    <ContainerCard HeaderComponent={<div style={{ display: 'flex', justifyContent: 'space-between', flex: '1' }}>Editing {selectedDay}</div>}>
                        <NotifyChainComponent chain={chain} users={users} changeNotifyChain={setChain} editing hideOnCall />
                        <div className={styles['actions']} style={{ justifyContent: 'flex-start' }}>
                            <Button title="Cancel" variant='ghost' size="s" onClick={() => {setChain(null); setSelectedDay(null)}} />
                            <Button title={`Save ${selectedDay}`} size="s" onClick={handleSaveNotifyChain} />
                        </div>
                    </ContainerCard>
                )}
                <div className={styles['actions']}>
                    <Button title="Cancel" variant="ghost" size="s" onClick={onCancel} />
                    <Button title="Save Roster Details" size="s" onClick={handleSaveRoster} disabled={!isDirty} />
                </div>
            </ContainerCard>
        </div>
    );
}

export default ManageRosterPage;
