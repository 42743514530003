/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateAlertRequest
 */
export interface UpdateAlertRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateAlertRequest
     */
    clientReference?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAlertRequest
     */
    closeSummary?: string;
}

/**
 * Check if a given object implements the UpdateAlertRequest interface.
 */
export function instanceOfUpdateAlertRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateAlertRequestFromJSON(json: any): UpdateAlertRequest {
    return UpdateAlertRequestFromJSONTyped(json, false);
}

export function UpdateAlertRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateAlertRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientReference': !exists(json, 'clientReference') ? undefined : json['clientReference'],
        'closeSummary': !exists(json, 'closeSummary') ? undefined : json['closeSummary'],
    };
}

export function UpdateAlertRequestToJSON(value?: UpdateAlertRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'clientReference': value.clientReference,
        'closeSummary': value.closeSummary,
    };
}

